import React, { useState, useEffect } from 'react';
import './ForgotPassword.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface ForgotPasswordProps {
  onClose: () => void; // Function to switch back to sign-in
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onClose }) => {
  const [rEmail, setREmail] = useState('');
  const [rShowEmail, setRShowEmail] = useState(true);
  const [otp, setOtp] = useState('');
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [timer, setTimer] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isResendActive, setIsResendActive] = useState(true);
  const [errorRMessage, setErrorRMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false); // For toggling password visibility
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  // Handle sending OTP
  const handleSendOtp = async () => {
    setErrorMessage('');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!rEmail) {
      setErrorRMessage('Enter your email address.');
      return;
    }
    if (!emailRegex.test(rEmail)) {
      setErrorRMessage('Please enter a valid email address.');
      return;
    }

    try {
      const response = await fetch(
        'https://espello.co/java_service/registration/api/v1/resendOTP',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            userId: null,
            email: rEmail,
            verificationModule: 'email',
          }),
        }
      );
      const data = await response.json();

      if (data.status === 'success' && data.data === true) {
        setShowOTPInput(true);
        setTimer(60); // Start countdown
        setIsResendActive(false);
      } else {
        alert(`Error: ${data.data.errorDescription}`);
      }
    } catch (error) {
      console.error('Error during OTP resend:', error);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleVerifyOtp = async () => {
    try {
      let email = rEmail;
      const response = await fetch(
        'https://espello.co/java_service/registration/api/v1/verifyOTP',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            userId: null,
            email,
            verificationModule: 'email',
            otp,
          }),
        }
      );
      const data = await response.json();

      if (data.data.verified === true) {
        setEmailVerified(true);
        setShowPasswordInput(true);
        // setErrorotpRMessage('');
      } else {
        // alert(`Error: ${data.data.errorDescription}`);
        setErrorRMessage(`${data.data.errorDescription}`);
      }
    } catch (error) {
      console.error('Error during OTP verification:', error);
    }
  };

  // Handle OTP Verification and Password Reset
  const handleResetPassword = async () => {
    if (!emailVerified || password !== confirmPassword) {
      setErrorMessage('Passwords does not match.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await fetch(
        'https://espello.co/java_service/registration/api/v1/setPassword',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: rEmail,
            userId: null,
            password: password,
          }),
        }
      );
      const data = await response.json();

      if (data.status === 'success') {
        setSuccessMessage('Password modified successfully!');
        clearForm();
        setRShowEmail(false);
      } else {
        setErrorMessage('Failed to set password.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while setting the password.');
    } finally {
      setIsLoading(false);
    }
  };

  // Countdown for OTP resend
  useEffect(() => {
    if (timer > 0 && showOTPInput && !emailVerified) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setIsResendActive(true);
    }
  }, [timer, showOTPInput, emailVerified]);

  // Clear form after reset
  const clearForm = () => {
    setREmail('');
    setOtp('');
    setShowOTPInput(false);
    setPassword('');
    setConfirmPassword('');
    setEmailVerified(false);
    setShowPasswordInput(false);
  };

  return (
    <div className="forgot-password-containerx">
      {rShowEmail && (
        <h2 className="forgot-password-headerx">Forgot Password</h2>
      )}

      {/* Email Input */}
      {!showOTPInput && rShowEmail && (
        <>
          <input
            type="email"
            className="forgot-password-inputx"
            placeholder="Enter your email"
            value={rEmail}
            onChange={(e) => setREmail(e.target.value)}
            required
          />
          <button className="forgot-password-buttonx" onClick={handleSendOtp}>
            Send OTP
          </button>
          {errorRMessage && (
            <p className="forgot-password-errorx">{errorRMessage}</p>
          )}
        </>
      )}

      {/* OTP Input */}
      {showOTPInput && !showPasswordInput && (
        <>
          <input
            type="text"
            className="forgot-password-inputx"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <button className="forgot-password-buttonx" onClick={handleVerifyOtp}>
            Verify OTP
          </button>
          <p className="authx-resend-otp-text">
            {isResendActive ? (
              <span onClick={handleSendOtp} className="resend-active">
                Resend OTP
              </span>
            ) : (
              `Resend OTP in ${timer}s`
            )}
          </p>
        </>
      )}

      {/* Password Input */}
      {showPasswordInput && (
        <>
          <div className="authx-forgot-password-wrapper">
            <input
              type={passwordVisible ? 'text' : 'password'}
              className="forgot-password-inputx"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FontAwesomeIcon
              icon={passwordVisible ? faEye : faEyeSlash}
              className="authx-forgot-eye-icon"
              onClick={togglePasswordVisibility}
            />
          </div>
          <div className="authx-forgot-password-wrapper">
          <input
            type={confirmPasswordVisible ? 'text' : 'password'}
            className="forgot-password-inputx"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
         
            <FontAwesomeIcon
              icon={confirmPasswordVisible ? faEye : faEyeSlash}
              className="authx-forgot-eye-icon"
              onClick={toggleConfirmPasswordVisibility}
            />
          </div>
          <button
            className="forgot-password-buttonx"
            onClick={handleResetPassword}
          >
            Set New Password
          </button>
          {errorMessage && (
            <p className="forgot-password-errorx">{errorMessage}</p>
          )}
        </>
      )}

      {/* Success Message */}
      {successMessage && (
        <p className="forgot-password-successx">{successMessage}</p>
      )}

      {/* Close Button */}
      <button className="forgot-password-closex" onClick={onClose}>
        Close
      </button>
    </div>
  );
};

export default ForgotPassword;
