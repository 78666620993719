import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import './MainContent.css';
import quashLogo from './images/quash.jpeg';
import { useNavigate } from 'react-router-dom';
import { PATH_LOGIN } from '../../../util/SiteRoutes';

const features = [
  'SCREENING CANDIDATES',
  'HR ROUND',
  'ROLE-SPECIFIC ASSESSMENTS',
];
const companies = [
  { name: 'Quash', logo: quashLogo },
  // { name: "Catco", logo: catcoLogo },
  // { name: "Kol Crafts", logo: kolLogo }
];

const demoUrl = 'https://cal.com/abhishek-agarwal-34kebm/demo-call-espello-ai';



const handleDemoButtonClick = () => {
  window.open(demoUrl, '_blank');
};


const MainContent: React.FC = () => {
  const navigate = useNavigate();
  const handleTryInterviewButtonClick = () => {
    navigate('/login');
  };

  return (
    <div className="new-home-container">
      <main className="new-home-main-content">
        <div className="new-home-section new-home-intro">
          <p className="new-home-user-count">
            Successfully used by{' '}
            <span style={{ fontWeight: 'bold' }}>500+ Users</span>{' '}
          </p>
          <h1 className="new-home-title">
            AI Enabled{' '}
            <span className="new-home-highlight">
              <TypeAnimation
                sequence={[
                  'Smooooth',
                  2500,
                  '',
                  1500,
                  'Product',
                  2500,
                  '',
                  1500,
                  'Design',
                  2500,
                  '',
                  1500,
                ]}
                wrapper="span"
                cursor={true}
                repeat={Infinity}
              />
            </span>{' '}
            Hiring
          </h1>
          <p className="new-home-description">
            Let our AI interviewer take care of the busy work, while your team
            do the real work
          </p>
        </div>
        <div className="new-home-section new-home-features-container">
          <div className="new-home-features">
            {features.map((feature, index) => (
              <div key={index} className="new-home-feature">
                {feature}
              </div>
            ))}
          </div>
        </div>
        <div className="new-home-demo-interview-buttons">
          <button
            className="new-home-demo-button"
            onClick={handleDemoButtonClick}
          >
            Book demo for Enterprises
          </button>
          <button
            className="new-home-try-interview-button"
            onClick={handleTryInterviewButtonClick}
          >
            Try interview now
          </button>
        </div>
        <div className="new-home-section new-home-companies">
          <p className="new-home-companies-text">
            Trusted and used by people from
          </p>
          <div className="new-home-companies-list">
            {companies.map((company, index) => (
              <div key={index} className="new-home-company">
                <img
                  src={company.logo}
                  alt={`${company.name} logo`}
                  className="new-home-company-logo"
                />
                <span>{company.name}</span>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default MainContent;
