// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background: #121212;
  box-sizing: border-box;
}

#root {
  width: 100%;
}

::selection {
  background: #ff8371;
  color: #f4eae7;
}

.container {
  /* width: 1280px; */
  margin: 0 auto;
}

@media screen and (max-width: 435px) {
  .container {
    max-width: 435px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":["body {\n  background: #121212;\n  box-sizing: border-box;\n}\n\n#root {\n  width: 100%;\n}\n\n::selection {\n  background: #ff8371;\n  color: #f4eae7;\n}\n\n.container {\n  /* width: 1280px; */\n  margin: 0 auto;\n}\n\n@media screen and (max-width: 435px) {\n  .container {\n    max-width: 435px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
