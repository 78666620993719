import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  Ref,
} from "react";
import useMediaRecorder from "./useMediaRecorder";
import CameraRecorder from "./CameraRecorder";
import { VideoQuality, WEBM, VIDEO_WEBM, VIDEO_MP4 } from "./videoConstants";
import { RECORDING_FORMAT, RECORDING_QUALITY, SERVICE_URL_JAVA } from "../../util/AppConstants";
import JSZip from "jszip";  // Import JSZip
import pako from "pako";

interface VideoRecorderProps {
  permissionGranted: boolean;
}

const videoQuality = RECORDING_QUALITY as VideoQuality;
const videoFormat = RECORDING_FORMAT;

const VideoRecorder = forwardRef(
  (
    { permissionGranted }: VideoRecorderProps,
    ref: Ref<{ stopRecording: () => void; handleSaveVideo: () => void }>
  ) => {
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [personCount, setPersonCount] = useState<number>(0);
    const { startRecording, stopRecording, recordedChunks, isRecording } =
      useMediaRecorder(stream);

    useEffect(() => {
      if (permissionGranted && !isRecording) {
        startRecording();
      }
    }, [permissionGranted, isRecording, startRecording]);

    useEffect(() => {
      if (personCount > 1) {
        alert("More than 1 face detected");
        setPersonCount(1);
      }
    }, [personCount]);

    useImperativeHandle(ref, () => ({
      stopRecording,
      handleSaveVideo,
    }));

    console.log(`recordedchunks ${recordedChunks}`)

    const handleSaveVideo = async () => {
      console.log('saving');
      
      if (recordedChunks.length) {
        const blob = new Blob(recordedChunks, {
          type: videoFormat === WEBM ? VIDEO_WEBM : VIDEO_MP4,
        });
    
        // Initialize a new JSZip instance
        const zip = new JSZip();
    
        // Add the video Blob to the ZIP file
        zip.file(`${localStorage.getItem('session_id')}.${videoFormat}`, blob);
    
        // Generate the ZIP file as a Blob
        const zipBlob = await zip.generateAsync({ type: "blob" });
    
        // Create FormData and append the ZIP Blob instead of the video Blob
        const formData = new FormData();
        formData.append('video', zipBlob, `${localStorage.getItem('session_id')}.zip`);
    
        try {
          const response = await fetch(`${SERVICE_URL_JAVA}/media/api/v1/uploadVideo`, {
            method: 'POST',
            body: formData,
          });
    
          if (response.ok) {
            console.log('Video ZIP uploaded successfully');
          } else {
            console.error('Failed to upload video ZIP');
          }
        } catch (error) {
          console.error('Error occurred during video ZIP upload:', error);
        }
      }
    };

    // const handleSaveVideo = async () => {
    //   console.log('saving');
      
    //   if (recordedChunks.length) {
    //     const blob = new Blob(recordedChunks, {
    //       type: videoFormat === WEBM ? VIDEO_WEBM : VIDEO_MP4,
    //     });
    
    //     // Convert the Blob to an ArrayBuffer
    //     const arrayBuffer = await blob.arrayBuffer();
    
    //     // Compress the ArrayBuffer using Pako
    //     const compressedArray = pako.deflate(new Uint8Array(arrayBuffer));
    
    //     // Convert the compressed array back to a Blob
    //     const compressedBlob = new Blob([compressedArray], {
    //       type: "application/octet-stream",
    //     });
    
    //     // Create FormData and append the compressed Blob
    //     const formData = new FormData();
    //     formData.append('video', compressedBlob, `${localStorage.getItem('session_id')}.deflate`);
    
    //     try {
    //       const response = await fetch(`${SERVICE_URL_JAVA}/media/api/v1/uploadVideo`, {
    //         method: 'POST',
    //         body: formData,
    //       });
    
    //       if (response.ok) {
    //         console.log('Video uploaded successfully');
    //       } else {
    //         console.error('Failed to upload video');
    //       }
    //     } catch (error) {
    //       console.error('Error occurred during video upload:', error);
    //     }
    //   }
    // };
    

    return (
      <CameraRecorder
        videoQuality={videoQuality}
        stream={stream}
        setStream={setStream}
        // setPersonCount={setPersonCount}
        permissionGranted={permissionGranted}
      />
    );
  }
);

export default VideoRecorder;