import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './index.css';
import {
  PATH_AUTH_DashBoard,
  PATH_CREATE_SESSION,
  PATH_HOME,
  PATH_ONGOING_SESSION,
} from '../../util/SiteRoutes';

const Subheader: FC = () => {
  const [isAndroidHeaderCollapsed, setIsAndroidHeaderCollapsed] =
    useState<boolean>(true);
  const [email, setEmail] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === PATH_ONGOING_SESSION ||
      location.pathname === PATH_CREATE_SESSION ||
      location.pathname === PATH_AUTH_DashBoard
    ) {
      const storedEmail = localStorage.getItem('email');
      setEmail(storedEmail);
    }
  }, [location.pathname]);

  const onClickLogo = () => {
    navigate(PATH_HOME);
  };

  const onLogout = () => {
    localStorage.clear();
    navigate(PATH_HOME);
  };

  return (
    <React.Fragment>
      <div className="subheader-pc">
        <div className="subheader-left" onClick={onClickLogo}>
          <div className="subheader-left-logo-icon">
            <svg
              width="50"
              height="50"
              viewBox="0 0 1778 1591"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M773.734 725.293C938.965 712.013 1143.92 695.541 1169.89 495.056C1222.35 90.1593 858.047 0 565.005 0C271.963 0 -20.2171 96.7163 1.09972 495.056C31.5622 1064.3 913.068 1340.91 624.018 947.612C478.092 749.054 605.266 738.833 773.734 725.293ZM545.415 504.992C593.907 494.975 626.841 455.983 618.974 417.901C611.108 379.819 565.42 357.068 516.927 367.085C468.435 377.102 435.501 416.094 443.367 454.176C451.234 492.258 496.922 515.009 545.415 504.992Z"
                fill="#FF8371"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1214.25 1290.79C1112.22 1248.92 985.659 1196.99 1009.79 1064.67C1058.51 797.436 1307.65 814.048 1493.37 873.374C1679.1 932.7 1844.69 1053.15 1750.54 1301.29C1615.99 1655.9 1001.31 1652.75 1264.13 1462C1396.81 1365.7 1318.28 1333.48 1214.25 1290.79ZM1397.4 1208.36C1361.02 1187.88 1344.58 1148.11 1360.66 1119.54C1376.75 1090.97 1419.29 1084.42 1455.66 1104.91C1492.04 1125.4 1508.49 1165.16 1492.4 1193.73C1476.31 1222.3 1433.78 1228.85 1397.4 1208.36Z"
                fill="#FF8371"
              />
            </svg>
          </div>
          <div className="subheader-left-logo">Espello AI</div>
        </div>
        {(location.pathname === PATH_ONGOING_SESSION ||
          location.pathname === PATH_CREATE_SESSION ||
          location.pathname === PATH_AUTH_DashBoard) && (
          <div className="subheader-right">
            {email && <div className="subheader-email">{email}</div>}
            <button className="subheader-logout" onClick={onLogout}>
              Logout
            </button>
          </div>
        )}
      </div>
      <div className="subheader-android">
        <div className="subheader-android-left" onClick={onClickLogo}>
          <svg
            width="37"
            height="33"
            viewBox="0 0 37 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.8847 14.8902C19.2769 14.6176 23.4846 14.2794 24.0178 10.1635C25.0948 1.85097 17.6157 0 11.5995 0C5.5834 0 -0.415057 1.98558 0.0225772 10.1635C0.647971 21.85 18.7452 27.5289 12.8111 19.4545C9.81521 15.3781 12.4261 15.1682 15.8847 14.8902ZM11.1966 10.3682C12.1922 10.1626 12.8683 9.36206 12.7068 8.58024C12.5453 7.79842 11.6073 7.33134 10.6118 7.53699C9.61622 7.74264 8.94009 8.54315 9.10159 9.32497C9.26309 10.1068 10.2011 10.5739 11.1966 10.3682Z"
              fill="#FF8371"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.9291 26.4995C22.8343 25.6399 20.2361 24.5738 20.7314 21.8572C21.7317 16.3709 26.8465 16.7119 30.6594 17.9299C34.4723 19.1478 37.872 21.6206 35.9391 26.715C33.1768 33.9951 20.5574 33.9305 25.9531 30.0144C28.677 28.0374 27.0648 27.3758 24.9291 26.4995ZM28.6887 24.8065C27.9419 24.3859 27.6042 23.5695 27.9345 22.983C28.2648 22.3965 29.138 22.262 29.8848 22.6826C30.6317 23.1032 30.9693 23.9196 30.639 24.5061C30.3087 25.0926 29.4356 25.2271 28.6887 24.8065Z"
              fill="#FF8371"
            />
          </svg>
          <div className="subheader-left-logo">Espello AI</div>
        </div>
        {location.pathname === PATH_ONGOING_SESSION && (
          <div className="subheader-android-right">
            {email && <div className="subheader-android-email">{email}</div>}
            <button className="subheader-android-logout" onClick={onLogout}>
              Logout
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Subheader;
