import './index.css';

const SubFooter = () => {
  const mail = 'hello@espello.co';
  const phoneNumber = '+919479305333';
  const waitlistPath = '/user-waitlist';

  function openMail() {
    const recipient = mail;
    const subject = 'Your Subject';
    const body = 'Your Body';
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  }

  function redirectToWaitlist() {
    window.location.href = waitlistPath;
  }

  return (
    <div className="sub-footer">
      <div className="sub-footer-left">
        <div>Copyright © 2024 Espello</div>
      </div>
      <div className="sub-footer-right">
        <div className="sub-footer-right-contact">Contact us if you need help:</div>
        <div className="sub-footer-right-details">
          <div className="sub-footer-right-mail" onClick={openMail}>
            {mail}
          </div>
          <span style={{ color: '#6E6E6E' }}>|</span>
          <div className="sub-footer-right-phone">
            {phoneNumber}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
