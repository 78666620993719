// src/components/CameraRecorder.tsx

import React, { useRef, useEffect, useState } from "react";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";
import { VideoQuality, qualityOptions } from "./videoConstants";

interface CameraRecorderProps {
  videoQuality: VideoQuality;
  stream: MediaStream | null;
  setStream: React.Dispatch<React.SetStateAction<MediaStream | null>>;
  // setPersonCount: React.Dispatch<React.SetStateAction<number>>;
  permissionGranted: boolean;
}

interface DropDownProps {
  options: string[];
  icon: JSX.Element;
  label: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
}

const DropdownButton: React.FC<DropDownProps> = ({
  options,
  icon,
  label,
  setSelectedOption,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="dropdown-container">
      <button className="dropdown-button" onClick={toggleDropdown}>
        <span>{icon}</span>
        <span>{label}</span>
      </button>
      {isOpen && (
        <div className="dropdown">
          {options.map((option) => (
            <div
              key={option}
              className="dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CameraRecorder: React.FC<CameraRecorderProps> = ({
  videoQuality,
  stream,
  setStream,
  // setPersonCount,
  permissionGranted,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const getMediaStream = async () => {
      const constraints = {
        video: {
          width: qualityOptions[videoQuality].width,
          height: qualityOptions[videoQuality].height,
          frameRate: qualityOptions[videoQuality].frameRate,
        },
        audio: {
          echoCancellation: true, // Enable echo cancellation
        },
      };
      
    
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
        setStream(mediaStream);
        if (videoRef.current) {
          videoRef.current.srcObject = mediaStream;
        }
      } catch (err) {
        console.error("Error accessing media devices.", err);
      }
    };

    getMediaStream();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [videoQuality]);

  useEffect(() => {
    let model: cocoSsd.ObjectDetection | null = null;

    const loadModel = async () => {
      model = await cocoSsd.load();
      detectFrame();
    };

    const detectFrame = async () => {
      if (!model || !videoRef.current || !canvasRef.current) return;

      if (videoRef.current.videoWidth === 0 || videoRef.current.videoHeight === 0) {
        requestAnimationFrame(detectFrame);
        return;
      }

      const context = canvasRef.current.getContext("2d");
      if (context) {
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;

        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

        const predictions = await model.detect(videoRef.current);

        predictions.forEach((prediction) => {
          if (prediction.class === "person") {
            context.strokeStyle = "red";
            context.lineWidth = 2;
            context.strokeRect(
              prediction.bbox[0],
              prediction.bbox[1],
              prediction.bbox[2],
              prediction.bbox[3]
            );
          }
        });

        // setPersonCount(
        //   predictions.filter((prediction) => prediction.class === "person").length
        // );
      }

      requestAnimationFrame(detectFrame);
    };

    const handleLoadedMetadata = () => {
      loadModel();
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("loadedmetadata", handleLoadedMetadata);
      }
    };
  }, []);

  return (
    <div
    style={{
      position:"relative",
      width:'100%',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      }}
    >
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width:'100%',
          borderRadius: "8px",
          zIndex: 2000,
        }}
      />
      <canvas
        ref={canvasRef}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width:'100%',
          borderRadius: "8px",
          zIndex: 1000,
        }}
      />
    </div>
  );
};

export default CameraRecorder;