import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import "./index.css";
import Header from "../../../../Header";
import { Model } from "../../../../Model";
import SubFooter from "../../../../SubFooter";
import VideoRecorder from "../../../../Recorder/VideoRecorder";
import { SERVICE_URL_JAVA, SERVICE_URL_PYTHON, USER_CURRENT_SESSION_COOKIE } from "../../../../../util/AppConstants";
import { useAuth } from "../../../../Account/AuthContext";
import Subheader from "../../../../SubHeader";

interface EnterpriseDisclaimerProps {
  permissionGranted: boolean;
  setPermissionGranted: React.Dispatch<React.SetStateAction<boolean>>;
  setPermissionDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setTimer: React.Dispatch<React.SetStateAction<number>>;
  permissionDialogVisible: boolean;
  handleStartSessionClick: () => void;
  proceedWithSession: () => void;
  videoRecordingComponent?: JSX.Element;
  setSessionId: React.Dispatch<React.SetStateAction<string>>;
}

const cardsTop = [
  {
    main: "Format:",
    heading: ["Single Session"],
    content: "There will be no option to pause, so please complete the entire interview in one sitting.",
  },
  {
    main: "_",
    heading: ["Timed Responses (90 seconds)"],
    content: "You will get 90 seconds to speak your answers and edit them if needed.",
  },
];

const cardsBottom = [
  {
    main: "Requirements:",
    heading: ["Use Google Chrome on PC", "Ensure working microphone & camera", "Ensure stable internet"],
    content: "",
  },
  {
    main: "Important Note:",
    heading: ["Do not switch tabs or exit fullscreen view", "Interview progress is not recoverable if interrupted"],
    content: "",
  }
];

const EnterpriseDisclaimer: React.FC<EnterpriseDisclaimerProps> = ({
  permissionGranted,
  setPermissionGranted,
  setPermissionDialogVisible,
  setTimer,
  permissionDialogVisible,
  handleStartSessionClick,
  proceedWithSession,
  videoRecordingComponent,
  setSessionId
}) => {
  const [checked, setChecked] = useState(false);
  const [otp, setOtp] = useState("");
  const { userDetails } = useAuth();
  const [email, setEmail] = useState('');

  const location = useLocation(); // Access location
  const navigate = useNavigate(); // Use navigate for programmatic navigation

  useEffect(() => {
    // Retrieve email from navigation state or local storage
    const stateEmail = location.state?.email as string;
    const storedEmail = localStorage.getItem('email');
    if (stateEmail) {
      setEmail(stateEmail);
      localStorage.setItem('email', stateEmail);
    } else if (storedEmail) {
      setEmail(storedEmail);
    }
  }, [location.state]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleStartSessionClickWithValidation();
    }
  };

  const handlePermissionGranted = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      setPermissionGranted(true);
      setPermissionDialogVisible(false);

      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            proceedWithSession();
          }
          return prevTimer - 1;
        });
      }, 1000);
    } catch (error) {
      console.error("Permission denied", error);
      alert("Permissions are required to proceed with the session.");
    }
  };

  const createSession = async (userId: string) => {
    const url = `${SERVICE_URL_PYTHON}/save_session_details`;
    const sessionDetails = {
      role: `${otp}`,
      mode: '',
      industry: '',
      companyType: '',
    };
    const data = { userId, sessionDetails };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();

      if (result?.data.status === 'success') {
        setSessionId(result?.data.sessionId)
        sessionStorage.setItem(USER_CURRENT_SESSION_COOKIE, 'true');
        handleStartSessionClick();
      } else {
        console.error('An unknown error occurred');
      }
    } catch (error: any) {
      console.error('Error:', error);
    }
  };

  const handleVerifyOtpAndStartSession = async () => {
    if (!checked || otp.trim() === "") {
      alert("Please agree to the instructions and enter a valid OTP to proceed.");
      return;
    }
    try {
      const response = await fetch(
        `${SERVICE_URL_JAVA}/enterprise/api/v1/verifyEnterpriseUser?uniqueCode=${otp}&email=${email}`,
        { method: 'GET' }
      );

      if (response.ok) {
        const result = await response.json();
        const { status, data } = result;

        if (status === 'success' && data.verified) {
          localStorage.setItem('isVerified', 'true');
          if (userDetails && userDetails.user_id) {
            await createSession(userDetails.user_id);
          } else {
            console.error('User details are not available');
          }
        } else {
          alert(data.errorDescription || 'Invalid OTP or email');
          localStorage.setItem('isVerified', 'false');
        }
      } else {
        alert('Error verifying OTP');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('Error verifying OTP');
    }
  };

  const handleStartSessionClickWithValidation = async () => {

    if (!checked) {
      alert("Please agree to the instructions to proceed.");
      return;
    }

    if (otp.trim() === "") {
      alert("Please enter a valid OTP.");
      return;
    }

    await handleVerifyOtpAndStartSession();
  };

  return (
    <React.Fragment>
      <Subheader />
      <div className="video-disclaimer-enterprise-container">
        <div className="disclaimer-enterprise-video-with-button-left">
          <div className="disclaimer-enterprise-video-left">
            {videoRecordingComponent}
          </div>
        </div>
        <div className="disclaimer-enterprise-container">
          <Model
            visible={permissionDialogVisible}
            actionContent="Grant Permission"
            action={handlePermissionGranted}
            message="This session requires access to your camera and microphone. Please grant permissions to proceed."
          />
          <div className="disclaimer-enterprise-main-dialog-box">
            <div className="disclaimer-enterprise-main-dialog-box-heading">Instructions</div>
            <div className="disclaimer-enterprise-main-dialog-box-content">
              <div className="disclaimer-enterprise-main-dialog-box-content-header">
                Welcome! This is your screening round for Quash. You’ll be speaking to Espello’s AI interviewer.
              </div>
              <div className="disclaimer-enterprise-main-dialog-box-content-main">
                <div className="disclaimer-enterprise-main-dialog-box-content-main-top">
                  <div className="disclaimer-enterprise-main-dialog-box-content-main-top-content">
                    {cardsTop.map((card, index) => (
                      <div className="disclaimer-enterprise-main-dialog-box-content-main-top-content-box" key={index}>
                        <div className="disclaimer-enterprise-main-dialog-box-content-main-top-heading"
                          style={card.main === "_" ? { color: "var(--Dark-Grey, #1D1D1D)" } : {}}>
                          {card.main}
                        </div>
                        <div className="disclaimer-enterprise-main-dialog-box-content-main-top-content-box-heading">
                          <ul>
                            {card.heading.map((value, index) => (
                              <li key={index}>{value}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="disclaimer-enterprise-main-dialog-box-content-main-top-content-box-content">
                          {card.content}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="disclaimer-enterprise-main-dialog-box-content-main-top">
                  <div className="disclaimer-enterprise-main-dialog-box-content-main-top-content">
                    {cardsBottom.map((card, index) => (
                      <div className="disclaimer-enterprise-main-dialog-box-content-main-top-content-box" key={index}>
                        <div className="disclaimer-enterprise-main-dialog-box-content-main-top-heading"
                          style={card.main === "_" ? { color: "var(--Dark-Grey, #1D1D1D)" } : {}}>
                          {card.main}
                        </div>
                        <div className="disclaimer-enterprise-main-dialog-box-content-main-top-content-box-heading">
                          <ul>
                            {card.heading.map((value, index) => (
                              <li key={index} style={value === "_" ? { color: "var(--Dark-Grey, #1D1D1D)" } : {}}>
                                {value}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="disclaimer-enterprise-main-dialog-box-content-main-top-content-box-content"
                          style={card.content === "_" ? { color: "var(--Dark-Grey, #1D1D1D)" } : {}}>
                          {card.content}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="disclaimer-conscent">
                <input type="checkbox" checked={checked} onChange={handleCheckboxChange} className="checkbox-input" />
                <span style={{ color: "white" }}>I have read the instructions mentioned above and wish to continue.</span>
              </div>
            </div>
            <div className="input-otp-startsession">
              <div className="disclaimer-enterprise-input-otp">
                <input
                  id="otp"
                  name="otp"
                  placeholder="Enter session code"
                  value={otp}
                  onChange={handleOtpChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
              {!permissionGranted && (
                <div
                  className="disclaimer-enterprise-main-dialog-box-button"
                  onClick={handleStartSessionClickWithValidation}
                >
                  Start Interview
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="disclaimer-enterprise-footer">
          <SubFooter />
        </div>
      </div>
    </React.Fragment>
  );
};

export default EnterpriseDisclaimer;
