import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../Account/AuthContext';

interface ProtectedRouteProps {
  element: JSX.Element;
  condition: () => boolean;
  redirectTo: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, condition, redirectTo }) => {
  const { userDetails } = useAuth();

  if (!condition() || !userDetails) {
    return <Navigate to={redirectTo} replace />;
  }

  return element;
};

export default ProtectedRoute;