export interface ButtonOption {
    displayLabel: string;
    label: string;
    active: boolean;
    selected: boolean;
}

export interface ButtonOptions {
    displayLabel: string;
    label: string;
    options: ButtonOption[]
}

export const sessionDetailButtonOptions: ButtonOptions[] = [
    {
        displayLabel: "role",
        label: "role",
        options: [
            {
                displayLabel: "Consultant",
                label: "consultant",
                active: true,
                selected: true
            },
            {
                displayLabel: "Product",
                label: "product",
                active: true,
                selected: false
            },
            {
                displayLabel: "Strategy",
                label: "strategy",
                active: false,
                selected: false
            }
        ]
    },
    {
        displayLabel: "company type",
        label: "companyType",
        options: [
            {
                displayLabel: "Strategy",
                label: "strategy",
                active: true,
                selected: true
            },
            {
                displayLabel: "Info.tech",
                label: "info.tech",
                active: false,
                selected: false
            },
            {
                displayLabel: "Analytics",
                label: "analytics",
                active: false,
                selected: false
            }
        ]
    },
    {
        displayLabel: "mode",
        label: "mode",
        options: [
            {
                displayLabel: "Challenge",
                label: "challenge",
                active: true,
                selected: true
            },
            {
                displayLabel: "Friendly",
                label: "friendly",
                active: false,
                selected: false
            },
            {
                displayLabel: "Assisted",
                label: "assisted",
                active: false,
                selected: false
            }
        ]
    },
    
];

export const industryOptions = [
    // 'Random',
    'Consumer Goods',
    'Retail',
    'Technology',
    'Healthcare',
    'Financial Services',
    'Energy',
    'Manufacturing',
    'Transportation and Logistics',
    'Media and Entertainment',
    'Hospitality and Travel',
    'Real Estate and Construction',
    'Education',
    'Agriculture',
    'Telecommunications',
    'Aerospace and Defense',
    'Non-Profit and Government',
];


export const caseTypeOptions =[
    'Type 1',
    'Type 2',
    'Type 3',
    'Type 4',
    'Type 5',
]