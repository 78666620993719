import React, { useState, useEffect } from 'react';
import './NotificationPopup.css'; // Import the CSS file

interface NotificationPopupProps {
  message: string;
}

const NotificationPopup: React.FC<NotificationPopupProps> = ({ message }) => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    // Show notification after a delay for smooth appearance
    const showTimer = setTimeout(() => {
      setVisible(true);
    }, 100); // Adjust delay as needed

    // Hide notification after 9-10 seconds
    const hideTimer = setTimeout(() => {
      setVisible(false);
    }, 10000); // Adjust duration as needed

    // Clean up timers to prevent memory leaks
    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    };
  }, []);

  const handleClose = () => {
    setVisible(false);
  };

  const containerClass = visible ? 'notification-container show' : 'notification-container';

  return (
    <div className={containerClass}>
      <div className="notification-popup">
        <div className="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="information-circle">
              <path
                id="Vector"
                d="M8 1.33333C4.32489 1.33333 1.33333 4.32489 1.33333 8C1.33333 11.6751 4.32489 14.6667 8 14.6667C11.6751 14.6667 14.6667 11.6751 14.6667 8C14.6667 4.32489 11.6751 1.33333 8 1.33333ZM7.33333 10.6667H8.66667V12H7.33333V10.6667ZM7.33333 4.66667H8.66667V9.33333H7.33333V4.66667Z"
                fill="#F4EAE7"
              />
            </g>
          </svg>
        </div>
        <div className="message">{message}</div>
        <button className="close-button" onClick={handleClose}>
          X
        </button>
      </div>
    </div>
  );
};

export default NotificationPopup;