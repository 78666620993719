import { useState } from 'react';
import './Footer.css';

const Footer = () => {
  const mail = 'hello@espello.co';
  const blogUrl = 'https://espello.notion.site/Short-Reads-to-Learn-More-About-Us-c2c22d4053f043a7b3857441dd6bedd6?pvs=4';
  const linkedinUrl = 'https://www.linkedin.com/company/espelloai/';

  const [showTooltip, setShowTooltip] = useState(false);

  function copyEmail() {
    navigator.clipboard.writeText(mail);
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000); // Hide tooltip after 2 seconds
  }

  function redirectToBlog() {
    window.open(blogUrl, '_blank');
  }

  function openLinkedIn() {
    window.open(linkedinUrl, '_blank');
  }

  return (
    <div className="new-home-footer">
      <div className="new-home-footer-left">
        <div>Copyright © 2024 Espello</div>
      </div>
      <div className="new-home-footer-right">
        <div className="new-home-footer-right-contact" onClick={redirectToBlog}>
          Blogs
        </div>
        <div className="new-home-footer-right-mail" onClick={copyEmail}>
          {mail}
          {showTooltip && <div className="tooltip">Copied</div>}
        </div>
        <div className="new-home-footer-right-phone" onClick={openLinkedIn}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13.8795 0.99707H2.19285C1.55427 0.99707 0.996338 1.45655 0.996338 2.08762V13.8002C0.996338 14.4347 1.55427 15.0001 2.19285 15.0001H13.876C14.518 15.0001 14.9994 14.4309 14.9994 13.8002V2.08762C15.0031 1.45655 14.518 0.99707 13.8795 0.99707ZM5.33697 12.6693H3.33091V6.432H5.33697V12.6693ZM4.40333 5.48367H4.38895C3.74694 5.48367 3.33122 5.00576 3.33122 4.4075C3.33122 3.79831 3.75788 3.33164 4.41427 3.33164C5.07066 3.33164 5.47231 3.79487 5.48669 4.4075C5.48638 5.00576 5.07066 5.48367 4.40333 5.48367ZM12.6686 12.6693H10.6625V9.25887C10.6625 8.44182 10.3706 7.88357 9.64478 7.88357C9.09029 7.88357 8.76209 8.25865 8.61612 8.62404C8.56142 8.75532 8.54673 8.93411 8.54673 9.11665V12.6693H6.54067V6.432H8.54673V7.3C8.83867 6.88429 9.29471 6.28603 10.3559 6.28603C11.6727 6.28603 12.6689 7.15404 12.6689 9.02538L12.6686 12.6693Z" fill="#F4EAE7"/>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Footer;
