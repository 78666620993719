import React from 'react';
import Header from "../Header";
import './index.css';
import Login from './Login';
import Subheader from '../SubHeader';
import BackgroundCircles from '../BackgroundImage/BackgroundCircles';
import NavBar from '../NewHome/NavBar/NavBar';

const Account = () => {




    return (
        <>
            {/* <Subheader /> */}<NavBar />
            <BackgroundCircles />
            <Login />
        </>
    );
}

export default Account;

