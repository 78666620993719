import { ELEVENLABS_API_KEY} from "../../../../../util/AppConstants";


export const createAudioStreamFromText = async (text: string): Promise<Blob> => {
    console.log("Making API request with text:", text);
  
    const response = await fetch('https://api.elevenlabs.io/v1/text-to-speech/mCQMfsqGDT6IDkEKR20a/stream', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'xi-api-key': ELEVENLABS_API_KEY || '',
      },
      body: JSON.stringify({
        text,
        model_id: "eleven_monolingual_v1",
        voice_settings: {
          stability: 0.6,
          similarity_boost: 0.9,
        },
      }),
    });
  
    if (!response.ok) {
      const errorMessage = `Error: ${response.statusText}`;
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  
    // Directly return the response as a Blob
    return response.blob();
  };
