import { useState, useEffect, useRef } from 'react';

const useMediaRecorder = (stream: MediaStream | null) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null); // Use useRef to keep the reference

  useEffect(() => {
    if (stream) {
      mediaRecorderRef.current = new MediaRecorder(stream);
  
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
        }
      };
  
      mediaRecorderRef.current.onstop = () => {
        // Handle the stop event if needed
      };
    }
  
    return () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [stream]);
  

  const startRecording = () => {
    console.log('starting')
    if (mediaRecorderRef.current) {
      setRecordedChunks([]); // Reset recorded chunks
      mediaRecorderRef.current.start();
      setIsRecording(true);
    }
  };

  const stopRecording = () => {
    console.log('stopping')
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  return { startRecording, stopRecording, recordedChunks, isRecording };
};

export default useMediaRecorder;