import React, { useState } from 'react';
import './DashBoard.css'; // Updated CSS to reflect the changes
import DemoImage from './imageDemo.jpg'; // Ensure the correct path
import Subheader from '../SubHeader';
import { SERVICE_URL_PYTHON, USER_CURRENT_SESSION_COOKIE } from '../../util/AppConstants';
import { PATH_CURRENT_SESSION_WITHOUT_SESSION_ID } from '../../util/SiteRoutes';
import { useNavigate } from 'react-router-dom';
import RecruitmentDetails from './RecruitmentDetails/RecruitmentDetails';

const DashBoard: React.FC = () => {
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();

  const createSession = async () => {
  

    const url = `${SERVICE_URL_PYTHON}/save_session_details`;
    const sessionDetails = {
      role: `demo`,
      mode: '',
      industry: '',
      companyType: '',
    };
    const userId = localStorage.getItem('userID')
    const data = {userId,sessionDetails };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();

      if (result?.data.status === 'success') {
        sessionStorage.setItem(USER_CURRENT_SESSION_COOKIE, 'true');
        console.log(result.data.sessionId)
        navigate(
          
          `${PATH_CURRENT_SESSION_WITHOUT_SESSION_ID}/${result?.data.sessionId}`
        );
      } else {
        console.error('An unknown error occurred');
        // TODO: Handle session not created
      }
    } catch (error: any) {
      console.error('Error:', error);
    }
  };

  const startSession = async () => {
    if (localStorage.getItem('loginWay')=='Recruiter'&& localStorage.getItem('email')) {
      await createSession();
    } else {
      console.error('User details are missing');
    }
  };

  const handleSubmitClick = () => {
    // Send the input value to the API
    fetch('/api/submit', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ input: inputValue }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('API Response:', data);
      });
  };

  const handleBookDemoClick = () => {
    window.open(
      'https://cal.com/abhishek-agarwal-34kebm/demo-call-espello-ai',
      '_blank'
    );
  };

  return (
    <div className="random-component-page-container">
      <div className="random-component-navbar">

        <Subheader />
      </div>
      {/* <RecruitmentDetails /> */}
      <div className="random-component-left-section">
        <div className="random-component-card">
          <img
            src={DemoImage}
            alt="Demo"
            className="random-component-card-image"
          />
          <button
            className="random-component-card-button"
            onClick={startSession}
          >
            Try it yourself
          </button>
        </div>
      </div>

      <div className="random-component-right-section">
        <div className="random-component-input-block">
          <h2 className="random-component-heading">
            Tell us your requirements
          </h2>
          <p className="random-component-text">
            We will get back after making adjustments according to your needs
          </p>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="random-component-input"
          />
          <button
            className="random-component-submit-button"
            onClick={handleSubmitClick}
          >
            Submit
          </button>
        </div>

        <div className="random-component-link-block">
          <h2 className="random-component-link-heading">
            Still not sure on how to get started?
          </h2>
          <p className="random-component-link-text">
            Don't worry, we are always here to help.
          </p>
          <button
            className="random-component-link-button"
            onClick={handleBookDemoClick}
          >
            Book a demo
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
