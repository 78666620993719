import React, { useEffect, useState } from 'react';
import { AnalysisParam, SessionAnalysis } from '../../../model/SessionAnalysis';
import GraphCircle from './GraphCircle';
import './index.css';
import { DownloadLogo, calculateSummaryRating } from './util';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_CREATE_SESSION, PATH_HOME } from '../../../util/SiteRoutes';
import { Loader } from '../../Loader';

export interface SummaryProps {}

const Summary: React.FC<SummaryProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [sessionAnalysis, setSessionAnalysis] =
    useState<SessionAnalysis | null>(null);

  useEffect(() => {
    if (!state?.sessionAnalysis) {
      navigate(PATH_CREATE_SESSION);
    } else {
      setSessionAnalysis(state.sessionAnalysis);
    }
  }, [state, navigate]);

  if (!sessionAnalysis) {
    return <Loader message="Retrieving session summary" />;
  }

  const analysisParams = sessionAnalysis.analysisParams;

  const handleStartNextSession = () => {
    navigate(PATH_CREATE_SESSION);
  };

  return (
    <div className="session-summary-container">
      <div className="session-summary-inner">
        <header className="session-summary-inner-header">
          <h1 className="session-summary-inner-header-review">
            Session Analysis
          </h1>
          {/* <Button content="Download transcript" logo={DownloadLogo} onClick={() => {}} />
                    <Button content="Download Session report" logo={DownloadLogo} onClick={() => {}} /> */}
        </header>
        <section className="session-summary-inner-summary">
          <div className="session-summary-inner-summary-main">
            <div className="session-summary-inner-summary-main-inner">
              <div className="session-summary-inner-summary-main-inner-inner">
                <GraphCircle value={calculateSummaryRating(analysisParams)} />
                <div className="session-summary-inner-summary-main-right">
                  <h2 className="session-summary-inner-summary-main-right-heading">
                    Case Summary
                  </h2>
                  <p className="session-summary-inner-summary-main-right-content">
                    {sessionAnalysis.summary}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="session-summary-inner-summary-secondary">
            <div className="session-summary-inner-summary-secondary-inner">
              {renderRows(analysisParams)}
            </div>
          </div>
        </section>
        <footer className="session-summary-inner-route">
          <button className="session-summary-inner-route-button" onClick={handleStartNextSession}>
            <span className="session-summary-inner-route-button-content">
              Start next session
            </span>
            <span className="session-summary-inner-route-button-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M5.74695 3.50073L10.2479 8.00171L5.74695 12.5027"
                  strokeWidth="1.00189"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </footer>
      </div>
    </div>
  );
};

const renderRows = (values: AnalysisParam[]) => {
  return values
    .reduce((rows, value, index) => {
      if (index % 2 === 0) rows.push([]);
      rows[rows.length - 1].push(value);
      return rows;
    }, [] as AnalysisParam[][])
    .map((row, rowIndex) => (
      <div
        className="session-summary-inner-summary-secondary-inner-row"
        key={`row-${rowIndex}`}
      >
        {row.map((value, i) => (
          <div
            className="session-summary-inner-summary-secondary-inner-row-card"
            key={`card-${rowIndex * 2 + i}`}
          >
            <div className="session-summary-inner-summary-secondary-inner-row-card-inner">
              <div className="circle-analysisParam">
                <div className="session-summary-inner-summary-secondary-inner-row-card-inner-content">
                  {value.analysisDetailTuple.analysisParam}
                </div>
                {/* <div style={{width:'15px'}}> */}
                <GraphCircle
                  value={value.analysisDetailTuple.analysisParamScore}
                  size={50}
                />
                {/* </div> */}
              </div>
              <div className="session-summary-inner-summary-secondary-inner-row-card-inner-content-desc">
                {value.analysisDetailTuple.analysisParamDesc}
              </div>
            </div>
          </div>
        ))}
      </div>
    ));
};

// interface ButtonProps {
//   content: string;
//   logo: JSX.Element;
//   onClick: () => void;
// }

// const Button: React.FC<ButtonProps> = ({ content, logo, onClick }) => (
//   <div className="session-summary-inner-header-button" onClick={onClick}>
//     <span className="session-summary-inner-header-button-logo">{logo}</span>
//     <span className="session-summary-inner-header-button-content">
//       {content}
//     </span>
//   </div>
// );

export default Summary;
