import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NotificationPopup from './NotificationPopup';
import { PATH_LOGIN, PATH_CREATE_SESSION, PATH_HOME, PATH_CURRENT_SESSION_WITH_SESSION_ID, PATH_DISCLAIMER, PATH_ENTERPRISE_LOGIN, PATH_ONGOING_SESSION } from '../../util/SiteRoutes';

const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

// const isChromeOnDesktop = () => {
//   const userAgent = navigator.userAgent;
//   const isChrome = /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);
//   const isEdge = /Edg/.test(userAgent);
//   // return (isChrome || isEdge) && !isMobile();
//   return isChrome && !isMobile();
// };

const NotificationHandler: React.FC = () => {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const onMobile = isMobile();
    // const onChromeDesktop = isChromeOnDesktop();
    const currentPath = location.pathname;

    if (onMobile && (currentPath === PATH_LOGIN || currentPath === PATH_CREATE_SESSION)) {
      navigate(PATH_HOME); // Redirect to home page
      setMessage("Only Supports Chrome Browser on Desktop");
      setShowNotification(true);
    } else if ((currentPath === PATH_LOGIN || currentPath === PATH_ONGOING_SESSION || currentPath === PATH_CREATE_SESSION || currentPath === PATH_ENTERPRISE_LOGIN)) {
      setMessage("Only Supports Chrome Browser on Desktop");
      setShowNotification(true);
    }

    const timer = setTimeout(() => {
      setShowNotification(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, [location.pathname, navigate]);

  return (
    <>
      {showNotification && <NotificationPopup message={message} />}
    </>
  );
};

export default NotificationHandler;