import Question from "./Question";
import ChatLoader from "./ChatLoader";
import Answer from "./Answer";
import React, { FC } from "react";
import { ConversationTurnContextModel } from "../../../../model/ConversationTurnContextModel";



export interface ChatContainerProps {
  timerOut: boolean;
  session_id: string;
  interviewerText: string;
  sendIntervieweeResponse: (
    intervieweeText: string,
    retries?: number
  ) => Promise<void>;
  conversationContext: ConversationTurnContextModel;
  stopRecording:()=>void;
}

const ChatContainer: FC<ChatContainerProps> = ({
  timerOut,
  session_id,
  interviewerText,
  sendIntervieweeResponse,
  conversationContext,
  stopRecording
}) => {

  return (
    <div className="chat-bot-container-main">
      <React.Fragment>
        <Question
          session_id={session_id}
          interviewerText={interviewerText}
          conversationContext={conversationContext}
          stopRecording={stopRecording}
        />
        <ChatLoader conversationContext={conversationContext} />
        <Answer
          timerOut={timerOut}
          sendIntervieweeResponse={sendIntervieweeResponse}
          conversationContext={conversationContext}
        />
      </React.Fragment>
    </div>
  );
};

export default ChatContainer;
