export const SERVICE_URL_PYTHON = process.env.REACT_APP_SERVICE_URL_PYTHON;
export const SERVICE_URL_JAVA = process.env.REACT_APP_SERVICE_URL_JAVA;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const USER_SESSION_KEY = process.env.REACT_APP_USER_SESSION_KEY || '';
export const SESSION_SECRET_KEY = process.env.REACT_APP_USER_SESSION_SECRET_KEY || '';
export const USER_CURRENT_SESSION_COOKIE = process.env.REACT_APP_USER_CURRENT_SESSION_COOKIE || '';

export const API_WAITLIST = `${SERVICE_URL_JAVA}/session/api/v1/joinWaitlist`;

export const SPEAKER_INITIAL_TEXT = 'Hi. Can you please introduce yourself? Maybe tell about your work experience.'
export const SPEAKER_LAST_TEXT = [
    "Have a great day",
    "Have a nice day",
    "Have a good day"
];

export const USER_EMAIL_KEY = 'userEmail';

export const USER_LOGIN_WAY_KEY = process.env.REACT_APP_USER_LOGIN_WAY_KEY || '';
export const RECORDING_QUALITY = process.env.REACT_APP_RECORDING_QUALITY || '720p';
export const RECORDING_FORMAT = process.env.REACT_APP_RECORDING_FORMAT || 'webm';
export const VOICE_TEXT_KEY = process.env.REACT_APP_DEEPGRAM_API_KEY || '';
export const VOICE_TEXT_ID = process.env.REACT_APP_DEEPGRAM_PROJECT_ID || '';
export const ELEVENLABS_API_KEY = process.env.REACT_APP_ELEVENLABS_API_KEY || '';