// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.session-summary-inner-summary-main-left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.session-summery-inner-summary-main-left-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
}
  `, "",{"version":3,"sources":["webpack://./src/components/Session/Summary/GraphCircle/index.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,kBAAkB;AACtB","sourcesContent":["\n.session-summary-inner-summary-main-left {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n    align-self: stretch;\n}\n\n.session-summery-inner-summary-main-left-box {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n    position: relative;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
