import React from 'react';
import './BackgroundCircles.css';

const BackgroundCircles: React.FC = () => {
  return (
    <div className="bg-image">
      <div className="bg-circle"></div>
      <div className="bg-circle"></div>
      <div className="bg-circle"></div>
      <div className="bg-circle"></div>
      <div className="bg-circle"></div>
      <div className="bg-circle"></div>
    </div>
  );
};

export default BackgroundCircles;
