import React from "react"
import "./index.css"

interface LoaderProps {
    message : string
}

export const Loader : React.FC<LoaderProps> = ({message}) => {

    return (
        <>
            <div className="spinner-container">
                <div className="spinner"></div>
                <div className="spinner-message">{message}</div>
            </div>
        </>
    )
}