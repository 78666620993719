import React, { useState, useEffect } from 'react';
import { ConversationTurnContextModel } from '../../../../../model/ConversationTurnContextModel';
import { ConversationTurn } from '../../../../../model/ConversationTurn';

interface ChatHeaderTimerProps {
  conversationContext: ConversationTurnContextModel;
  setTimerOut: React.Dispatch<React.SetStateAction<boolean>>;
  setLineWidth: React.Dispatch<React.SetStateAction<number>>;
}

const ChatHeaderTimer: React.FC<ChatHeaderTimerProps> = ({
  conversationContext,
  setTimerOut,
  setLineWidth,
}) => {
  const [seconds, setSeconds] = useState(90);

  useEffect(() => {
    if (seconds === 0) {
      conversationContext?.changeConversationTurn(ConversationTurn.WAITING);
      setTimerOut(true);
    } else {
      setTimerOut(false);
    }

    const interval = setInterval(() => {
      setSeconds(prevSeconds => Math.max(prevSeconds - 1, 0));
    }, 1000);

    setLineWidth((seconds / 90) * 100); // Set the line width based on remaining time

    return () => clearInterval(interval);
  }, [seconds, conversationContext, setTimerOut, setLineWidth]);

  return (
    <div className="chat-bot-container-header-main-centre">
      <div className="chat-bot-container-header-main-centre-1">{seconds}</div>
      <div className="chat-bot-container-header-main-centre-2">seconds</div>
    </div>
  );
};

export default ChatHeaderTimer;
