import React, { useState, useEffect } from 'react';
import './AuthComponent.css'; // Import the CSS file
import UniqueBackgroundCircles from './BackGroundLogin/UniqueBackgroundCircles';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import { PATH_AUTH_DashBoard} from '../../util/SiteRoutes';

const AuthComponent: React.FC = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState<number | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false); // Track if email is verified
  const [showOTPInput, setShowOTPInput] = useState(false); // Track if OTP input should be shown
  const [otp, setOtp] = useState(''); // Track the OTP entered by the user
  const [timer, setTimer] = useState(60); // Timer for OTP resend
  const [isResendActive, setIsResendActive] = useState(false); // Resend OTP state
  const [email, setEmail] = useState('');
  const [rEmail, setREmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorRMessage, setErrorRMessage] = useState('');
  const [errorotpRMessage, setErrorotpRMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); // For toggling password visibility
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [updateEmailKey, setUpdateEmailKey] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  // Toggle between Sign Up and Sign In panels
  const handleSignUpClick = () => {
    setIsActive(true);
    clearForm();
  };

  const handleSignInClick = () => {
    setIsActive(false);
    clearForm();
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  // Handle Verify Email Click
  const handleVerifyEmailClick = async () => {
    const checkemailUpdateKey = sessionStorage.getItem('UpdateEmail');
    if (checkemailUpdateKey && updateEmailKey) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!userName && !rEmail) {
        setErrorRMessage('Enter user name and email');
        return;
      }
      if (!rEmail) {
        setErrorRMessage('Enter your email address.');
        return;
      }
      if (!emailRegex.test(rEmail)) {
        setErrorRMessage('Please enter a valid email address.');
        return;
      }
      if (!userName) {
        setErrorRMessage('User name is required');
        return;
      }

      try {
        let email = rEmail;
        const response = await fetch(
          `https://espello.co/java_service/registration/api/v1/updateEmail?userId=${localStorage.getItem(
            'userId'
          )}&email=${email}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          }
        );
        const data = await response.json();

        if (data.data == true) {
          setUpdateEmailKey(false);
          localStorage.removeItem('emailUpdateKey');
          setShowOTPInput(true);

          handleResendOtp();
        } else {
          alert(`Error: Email already registered`);
        }
      } catch (error) {
        alert('An error occurred while updating the email. Please try again.');
      }
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userName && !rEmail) {
      setErrorRMessage('Enter user name and email');
      return;
    }
    if (!rEmail) {
      setErrorRMessage('Enter your email address.');
      return;
    }
    if (!emailRegex.test(rEmail)) {
      setErrorRMessage('Please enter a valid email address.');
      return;
    }
    if (!userName) {
      setErrorRMessage('User name is required');
      return;
    }

    setErrorRMessage('');
    try {
      let email = rEmail;
      const response = await fetch(
        'https://espello.co/java_service/registration/api/v1/register',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: userName,
            email,
            registrationMedium: 'email',
          }),
        }
      );

      const data = await response.json();
      console.log(data);

      if (data.status === 'success') {
        setUserId(data.data.userId); // Set the userId in the state
        localStorage.setItem('userId', data.data.userId); // Store the userId in localStorage
        console.log(data.data.userId);
        setShowOTPInput(true);
        setTimer(60);
        setIsResendActive(false);
      } else {
        alert(`Error: ${data.data.errorDescription}`);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  // Handle Update email

  const handleUpdateEmail = async () => {
    sessionStorage.setItem('UpdateEmail', 'true');
    setShowOTPInput(false);
    setIsResendActive(false);
    setREmail('');
    setUpdateEmailKey(true);
  };

  // Handle OTP input change
  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setOtp(e.target.value);

  // Handle OTP submission
  const handleOtpSubmit = async () => {
    const storedUserId = localStorage.getItem('userId');
    if (!storedUserId) {
      alert('User ID not found. Please verify your email first.');
      return;
    }

    try {
      let email = rEmail;
      const response = await fetch(
        'https://espello.co/java_service/registration/api/v1/verifyOTP',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            userId: storedUserId,
            email,
            verificationModule: 'email',
            otp,
          }),
        }
      );
      const data = await response.json();

      if (data.data.verified === true) {
        setEmailVerified(true);
        setErrorotpRMessage('');
      } else {
        // alert(`Error: ${data.data.errorDescription}`);
        setErrorotpRMessage(`${data.data.errorDescription}`);
      }
    } catch (error) {
      console.error('Error during OTP verification:', error);
    }
  };

  const clearForm = () => {
    setUserName('');
    setREmail('');
    setOtp('');
    setPassword('');
    setConfirmPassword('');
    setErrorMessage('');
    setErrorRMessage('');
    setErrorotpRMessage('');
    setEmailVerified(false);
    setShowOTPInput(false);
  };

  const handleRegister = async () => {
    const storedUserId = localStorage.getItem('userId');
    if (!storedUserId) {
      setErrorMessage('User ID not found. Please verify your email first.');
      return;
    }

    if (!emailVerified || !password || password !== confirmPassword) {
      setErrorMessage('Passwords do not match or email not verified.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');

    try {
      let email = rEmail;
      const response = await fetch(
        'https://espello.co/java_service/registration/api/v1/setPassword',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email, // Send the email along with the password
            userId: storedUserId,
            password: password,
          }),
        }
      );

      const data = await response.json();

      if (data.status === 'success' && data.data === true) {
        // Registration successful, show a success message
        alert('Your email has been registered successfully!');
        setIsActive(false);
        clearForm();
      } else {
        setErrorMessage('Failed to set password.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while setting the password.');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Resend OTP
  const handleResendOtp = async () => {
    // const storedUserId = localStorage.getItem('userId');
    setErrorotpRMessage('');
    if (!userId) return;
    try {
      let email = rEmail;
      const response = await fetch(
        'https://espello.co/java_service/registration/api/v1/resendOTP',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            userId: null,
            email,
            verificationModule: 'email',
          }),
        }
      );
      const data = await response.json();

      if (data.status === 'success') {
        setTimer(60);
        setIsResendActive(false);
      } else {
        alert(`Error: ${data.data.errorDescription}`);
      }
    } catch (error) {
      console.error('Error during OTP resend:', error);
    }
  };

  // Countdown timer for OTP resend
  useEffect(() => {
    if (timer > 0 && showOTPInput && !emailVerified) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setIsResendActive(true); // Enable resend button when timer reaches 0
    }
  }, [timer, showOTPInput, emailVerified]);

  // Handle Login Submission ----------

  const handleLoginSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate email and password fields
    if (!email || !password) {
      setErrorMessage('Both email and password are required.');
      return;
    }

    setIsLoading(true);
    setErrorMessage(''); // Clear any previous error message

    try {
      const response = await fetch(
        'https://espello.co/java_service/registration/api/v1/login',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            password,
          }),
        }
      );

      const data = await response.json();

      // Check if login was not successful
      if (data.data.loginSuccess === false) {
        if (data.data.errorDescription === 'Email not exists') {
          setErrorMessage('Email does not exist.');
        } else if (data.data.errorDescription === 'Wrong Password') {
          setErrorMessage('Incorrect password.');
        } else {
          setErrorMessage('Login failed. Please try again.');
        }
      } else {
        // Handle successful login
        console.log('Login successful:', data);
        localStorage.setItem('loginWay','Recruiter')
        localStorage.setItem('email',email);
        localStorage.setItem('userID',data.data.userId)
        navigate(PATH_AUTH_DashBoard);
        // Redirect or store token as needed
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (errorMessage) {
      console.log('Error Message Updated:', errorMessage);
    }
  }, [errorMessage]);

  const isRegisterButtonDisabled =
    !emailVerified || !password || password !== confirmPassword;

  const buttonStyle = {
    backgroundColor: isRegisterButtonDisabled ? '#f0f0f0' : '#ff8371', // Light gray for disabled, blue for enabled
    color: isRegisterButtonDisabled ? '#a0a0a0' : '#fff', // Gray text for disabled, white text for enabled
    border: `1px solid ${isRegisterButtonDisabled ? '#d0d0d0' : '#ff8371'}`, // Light gray border for disabled, blue border for enabled
    cursor: isRegisterButtonDisabled ? 'not-allowed' : 'pointer', // Change cursor to indicate disabled/enabled state
  };

  return (
    <>
      <div className="authx-parent">
        <div
          className={`containerx ${isActive ? 'right-panel-activex' : ''}`}
          id="containerx"
        >
          {/* Sign Up Form */}
          <div className="form-containerx sign-up-containerx">
            <form action="#" className="overall-form-authx">
              <h1 className="authx-header">Register</h1>

              <input
                className="authx-input"
                placeholder="Username"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />

              <div className="authx-email-field-wrapper">
                <input
                  className="authx-input email-input-with-tick"
                  type="email"
                  placeholder="Email"
                  value={rEmail}
                  onChange={(e) => setREmail(e.target.value)}
                  required
                />
                {/* Update Email */}
                <span
                  className={`authx-tick-inside ${
                    emailVerified ? 'green-tick' : 'grey-tick'
                  }`}
                >
                  &#10003;
                </span>
              </div>
              {errorRMessage && <p className="authx-error">{errorRMessage}</p>}
              {!emailVerified && !showOTPInput && (
                <p
                  className="authx-verify-text"
                  onClick={handleVerifyEmailClick}
                >
                  Verify Email
                </p>
              )}

              {showOTPInput && !emailVerified && (
                <>
                  <div className="authx-email-field-wrapper">
                    <input
                      className="authx-input otp-input-with-send"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={handleOtpChange}
                    />
                    <span className="authx-send-otp" onClick={handleOtpSubmit}>
                      Verify
                    </span>
                  </div>
                  {errorotpRMessage && (
                    <p className="authx-error">{errorotpRMessage}</p>
                  )}
                  <p className="authx-resend-otp-text">
                    {isResendActive ? (
                      <span onClick={handleResendOtp} className="resend-active">
                        Resend OTP
                      </span>
                    ) : (
                      `Resend OTP in ${timer}s`
                    )}
                  </p>
                </>
              )}

              {emailVerified && (
                <>
                  <div className="authx-password-wrapper">
                    <input
                      className="authx-input"
                      placeholder="Password"
                      type={passwordVisible ? 'text' : 'password'} // Toggle between text and password
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={passwordVisible ? faEye : faEyeSlash}
                      className="authx-eye-icon"
                      onClick={togglePasswordVisibility}
                    />
                  </div>

                  <div className="authx-password-wrapper">
                    <input
                      className="authx-input"
                      placeholder="Confirm Password"
                      type={confirmPasswordVisible ? 'text' : 'password'} // Toggle between text and password
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={confirmPasswordVisible ? faEye : faEyeSlash}
                      className="authx-eye-icon"
                      onClick={toggleConfirmPasswordVisibility}
                    />
                  </div>
                </>
              )}

              <button
                className="authx-button"
                style={buttonStyle}
                onClick={handleRegister}
                disabled={isRegisterButtonDisabled}
              >
                Register
              </button>
              {showOTPInput && !emailVerified && (
                <p
                  onClick={handleUpdateEmail}
                  className="authx-update-email-button"
                  role="button"
                  tabIndex={0}
                >
                  Update Email
                </p>
              )}
            </form>
          </div>

          {/* Sign In Form */}

          <div className="form-containerx sign-in-container">
            <form onSubmit={handleLoginSubmit} className="overall-form-authx">
              <h1 className="authx-header">Login</h1>

              <input
                className="authx-input"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="authx-password-wrapper">
                <input
                  className="authx-input"
                  type={passwordVisible ? 'text' : 'password'} // Toggle between text and password
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <FontAwesomeIcon
                  icon={passwordVisible ? faEye : faEyeSlash}
                  className="authx-eye-icon"
                  onClick={togglePasswordVisibility}
                  style={{
                    paddingBottom: passwordVisible ? '1px' : '5px', // Different right position
                  }}
                />
              </div>

              {errorMessage && <p className="authx-error">{errorMessage}</p>}
              <p
                onClick={() => setShowForgotPassword(true)}
                className="authx-update-email-button"
                role="button"
                tabIndex={0}
              >
                Forgot Password ?
              </p>
              <button
                className="authx-button"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? 'Logging in...' : 'Login'}
              </button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="bg-bubblesx">
                {[...Array(10)].map((_, index) => (
                  <li className="authx-bubble" key={index}></li>
                ))}
              </div>
              <div className="overlay-panel overlay-left">
                <h1 className="authx-header-overlay">Welcome Back!</h1>
                <p className="authx-paragraph">
                  To keep connected with us please login with your personal info
                </p>
                <button
                  className="authx-button ghost"
                  onClick={handleSignInClick}
                >
                  Sign In
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1 className="authx-header-overlay">Hello, Friend!</h1>
                <p className="authx-paragraph">
                  Enter your personal details and start your journey with us
                </p>
                <button
                  className="authx-button ghost"
                  onClick={handleSignUpClick}
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
          {/* Forgot Password Overlay */}
          {showForgotPassword && (
            <div className="forgot-password-overlay">
              <div className="forgot-password-content">
                <ForgotPassword onClose={() => setShowForgotPassword(false)} />
              </div>
            </div>
          )}
        </div>
        <UniqueBackgroundCircles />
      </div>
    </>
  );
};

export default AuthComponent;
