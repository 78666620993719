import React, { FC, useEffect, useRef, useState } from "react";
import './index.css';
import { ConversationTurn } from "../../../../../model/ConversationTurn";
import { ConversationTurnContextModel } from "../../../../../model/ConversationTurnContextModel";
import { SPEAKER_LAST_TEXT } from "../../../../../util/AppConstants";
import { useNavigate } from "react-router-dom";
import { PATH_USER_RATE } from "../../../../../util/SiteRoutes";
import { ExitMode } from "../../../../../model/ExitMode";
import { createAudioStreamFromText } from './elevenLabsAPI';

interface QuestionProps {
    session_id: string;
    interviewerText: string;
    conversationContext: ConversationTurnContextModel;
    stopRecording: () => void;
}

const Question: FC<QuestionProps> = ({ session_id, interviewerText, conversationContext, stopRecording }) => {
    const navigate = useNavigate();
    const [transcriptHTML, setTranscriptHTML] = useState<string>('');
    const [lastInterviewerText, setLastInterviewerText] = useState<string>('');
    const transcriptRef = useRef<HTMLParagraphElement>(null);

    const formatTranscript = (transcript: string): string => {
        return transcript
            .replace(/\*\*(.*?)\*\*/g, '<i><strong>$1</strong></i>') // Bold the text between **
            .replace(/(\d\.\s\*\*[^:]+\*\*[^.]+\.)/g, '$1<br><br>') // Add line breaks after each strategy
            .replace(/\n/g, '<br>'); // Ensure newlines are converted to <br> tags
    };

    const speakInterviewerText = async (text: string): Promise<void> => {
        const ttsMethod = process.env.REACT_APP_TTS_METHOD;

        if (ttsMethod === 'elevenLabsAPI') {
            // Implementation using elevenLabsAPI
            try {
                conversationContext?.changeConversationTurn(ConversationTurn.INTERVIEWER);
                const audioBlob = await createAudioStreamFromText(text);
                const audioUrl = URL.createObjectURL(audioBlob);
                const audio = new Audio(audioUrl);

                audio.play();

                audio.addEventListener('timeupdate', () => {
                    const spokenText = text.slice(0, Math.floor((audio.currentTime / audio.duration) * text.length));
                    const formattedSpokenText = formatTranscript(spokenText);
                    setTranscriptHTML(formattedSpokenText);

                    const isLastMessageFromInterviewer = SPEAKER_LAST_TEXT.some(lastText =>
                        formattedSpokenText.includes(lastText)
                    );

                    if (isLastMessageFromInterviewer) {
                        stopRecording();
                        setTimeout(() => {
                            navigate(PATH_USER_RATE, { state: { sessionId: session_id, exitMode: ExitMode.AUTO } });
                        }, 2000);
                    }
                });

                audio.onended = () => {
                    conversationContext?.changeConversationTurn(ConversationTurn.INTERVIEWEE);
                };
            } catch (error) {
                console.error('Error:', error);
            }
        } else if (ttsMethod === 'speechSynthesis') {
            // Implementation using speech synthesis
            conversationContext?.changeConversationTurn(ConversationTurn.INTERVIEWER);

            const utterance: SpeechSynthesisUtterance = new window.SpeechSynthesisUtterance(text);
            utterance.rate = 1;
            utterance.pitch = 1;

            utterance.onboundary = (event: SpeechSynthesisEvent): void => {
                const spokenText: string = text.slice(0, event.charIndex + event.charLength);
                const formattedSpokenText = formatTranscript(spokenText);
                setTranscriptHTML(formattedSpokenText);

                const isLastMessageFromInterviewer = SPEAKER_LAST_TEXT.some(lastText =>
                    formattedSpokenText.includes(lastText)
                );

                if (isLastMessageFromInterviewer) {
                    stopRecording();
                    setTimeout(() => {
                        navigate(PATH_USER_RATE, { state: { sessionId: session_id, exitMode: ExitMode.AUTO } });
                    }, 2000);
                }
            };

            utterance.onend = () => {
                conversationContext?.changeConversationTurn(ConversationTurn.INTERVIEWEE);
            };

            window.speechSynthesis.speak(utterance);
        } else {
            console.error(`Unknown TTS method: ${ttsMethod}`);
        }
    };

    useEffect(() => {
        if (interviewerText && interviewerText !== lastInterviewerText) {
            setLastInterviewerText(interviewerText);
            speakInterviewerText(interviewerText);
        }

        return () => {
            window.speechSynthesis.cancel();
        };
    }, [interviewerText]);

    useEffect(() => {
        if (transcriptRef.current) {
            transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
        }
    }, [transcriptHTML]);

    return (
        <div className="chat-bot-container-main-transcript">
            <div className="chat-bot-container-main-transcript-content" ref={transcriptRef}>
                <p dangerouslySetInnerHTML={{ __html: transcriptHTML }}></p>
            </div>
        </div>
    );
};

export default Question;
