import React from 'react';
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleComponent from '../Account/GoogleComponent';
import { GOOGLE_CLIENT_ID } from '../../util/AppConstants';
import { PATH_ONGOING_SESSION, PATH_VERIFY_OTP } from '../../util/SiteRoutes';
import Header from '../Header';
import SubFooter from '../SubFooter';
import Subheader from '../SubHeader';
import NavBar from '../NewHome/NavBar/NavBar';
import BackgroundCircles from '../BackgroundImage/BackgroundCircles';

const EnterpriseLogin = () => {
    if (!GOOGLE_CLIENT_ID) {
        console.error("Google Client ID is not defined");
        return null;
    }

    return (<React.Fragment>
        {/* <Subheader  /> */}
        <NavBar />
        <BackgroundCircles />
        <div className="user-login-container">
            <div className="user-login-container-inner">
                <div className="user-login-container-inner-left">
                    <div className="user-login-container-inner-left-content">
                        <div className="user-login-container-inner-left-content-heading">
                            <div className="user-login-container-inner-left-content-heading1">Login to Espello!</div>
                        </div>
                        <div className="user-login-container-inner-left-content-secondary-buttons">
                            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                                {/* <GoogleComponent redirectPath={PATH_VERIFY_OTP} loginWay='enterpriseLogin' /> */}
                                <GoogleComponent redirectPath={PATH_ONGOING_SESSION} loginWay='enterpriseLogin' />
                            </GoogleOAuthProvider>
                        </div>
                    </div>
                </div>
                <div className="user-login-container-inner-right"></div>
            </div>
        </div>
        <SubFooter />
        </React.Fragment>
    );
};

export default EnterpriseLogin;
