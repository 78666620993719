// Define path constants for better maintainability
export const PATH_HOME = '/';
export const PATH_LOGIN = '/login';
export const PATH_USER_WAITLIST = '/user-waitlist';
export const PATH_CREATE_SESSION = '/session/create-session';
export const PATH_CURRENT_SESSION_WITHOUT_SESSION_ID = '/session/current-session';
export const PATH_CURRENT_SESSION_WITH_SESSION_ID = `${PATH_CURRENT_SESSION_WITHOUT_SESSION_ID}/:sessionId`;
export const PATH_USER_RATE = `${PATH_CURRENT_SESSION_WITHOUT_SESSION_ID}/user-rate`;
export const PATH_CURRENT_SESSION_SUMMARY = `${PATH_CURRENT_SESSION_WITHOUT_SESSION_ID}/summary`;
export const PATH_BLOGS = 'https://espello.notion.site/Short-Reads-to-Learn-More-About-Us-c2c22d4053f043a7b3857441dd6bedd6?pvs=4'
export const PATH_DEFAULT = '/*';
export const PATH_ENTERPRISE_LOGIN = '/enterprise-login';
export const PATH_VERIFY_OTP = '/verify-otp';
export const PATH_DISCLAIMER = '/disclaimer';
export const PATH_ONGOING_SESSION = '/session/current-session/ongoing';
export const PATH_NEW_HOMEPAGE = '/new-homepage'; 
export const PATH_AUTH_COMPONENT = '/join-us';
export const PATH_AUTH_DashBoard = '/dashboard';
