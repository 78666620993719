import React, { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './NavBar.css';
import { PATH_HOME } from '../../../util/SiteRoutes';

const NavBar: FC = () => {
  const navigate = useNavigate();
  const location = useLocation(); // To check the current route

  const onClickLogo = () => {
    navigate(PATH_HOME);
  };

  const handleForStudentButtonClick = () => {
    const sessionKey = localStorage.getItem('espello-user-session');
    if (sessionKey) {
      navigate('/session/create-session'); // If session exists, navigate to the logged-in page
    } else {
      navigate('/login'); // Otherwise, navigate to the login page
    }
  };

  return (
    <div className="new-home-subheader-pc">
      <div className="new-home-subheader-left" onClick={onClickLogo}>
        <div className="new-home-subheader-left-logo-icon">
          <svg
            width="37"
            height="33"
            viewBox="0 0 37 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.8847 14.8902C19.2769 14.6176 23.4846 14.2794 24.0178 10.1635C25.0948 1.85097 17.6157 0 11.5995 0C5.5834 0 -0.415057 1.98558 0.0225772 10.1635C0.647971 21.85 18.7452 27.5289 12.8111 19.4545C9.81521 15.3781 12.4261 15.1682 15.8847 14.8902ZM11.1966 10.3682C12.1922 10.1626 12.8683 9.36206 12.7068 8.58024C12.5453 7.79842 11.6073 7.33134 10.6118 7.53699C9.61622 7.74264 8.94009 8.54315 9.10159 9.32497C9.26309 10.1068 10.2011 10.5739 11.1966 10.3682Z"
              fill="#FF8371"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.9291 26.4995C22.8343 25.6399 20.2361 24.5738 20.7314 21.8572C21.7317 16.3709 26.8465 16.7119 30.6594 17.9299C34.4723 19.1478 37.872 21.6206 35.9391 26.715C33.1768 33.9951 20.5574 33.9305 25.9531 30.0144C28.677 28.0374 27.0648 27.3758 24.9291 26.4995ZM28.6887 24.8065C27.9419 24.3859 27.6042 23.5695 27.9345 22.983C28.2648 22.3965 29.138 22.262 29.8848 22.6826C30.6317 23.1032 30.9693 23.9196 30.639 24.5061C30.3087 25.0926 29.4356 25.2271 28.6887 24.8065Z"
              fill="#FF8371"
            />
          </svg>
        </div>
        <div className="new-home-subheader-left-logo">Espello AI</div>
      </div>

      {/* Conditionally render the button only on the homepage */}
      {location.pathname === PATH_HOME && (
        <div className="new-home-subheader-right">
          <button className="home-button" onClick={handleForStudentButtonClick}>For Students</button>
        </div>
      )}
    </div>
  );
};

export default NavBar;
