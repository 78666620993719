// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.permission-dialog-overlay {
  font-family: 'DM Sans';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.permission-dialog {
  background: black;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 131, 113, 0.5);
  width: 400px; /* Adjust the width here */
  height: 175px; /* Adjust the height here */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 100px; */
  text-align: center;
}

.permission-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.permission-dialog p {
  color: #fff;
  margin: 0 0 15px 0;
}

.permission-dialog button {
  padding: 10px 20px;
  border: none;
  background-color: #ff8371;
  color: rgb(0, 0, 0);
  border-radius: 1px;
  cursor: pointer;
  font-weight: 600;
  font-family: 'DM Sans';
  text-transform: uppercase;
}

.permission-dialog button:hover {
  background-color: #ff8371;
}
`, "",{"version":3,"sources":["webpack://./src/components/Model/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,6CAA6C;EAC7C,YAAY,EAAE,0BAA0B;EACxC,aAAa,EAAE,2BAA2B;EAC1C,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".permission-dialog-overlay {\n  font-family: 'DM Sans';\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.8);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.permission-dialog {\n  background: black;\n  padding: 15px;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(255, 131, 113, 0.5);\n  width: 400px; /* Adjust the width here */\n  height: 175px; /* Adjust the height here */\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  /* gap: 100px; */\n  text-align: center;\n}\n\n.permission-dialog-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 25px;\n}\n\n.permission-dialog p {\n  color: #fff;\n  margin: 0 0 15px 0;\n}\n\n.permission-dialog button {\n  padding: 10px 20px;\n  border: none;\n  background-color: #ff8371;\n  color: rgb(0, 0, 0);\n  border-radius: 1px;\n  cursor: pointer;\n  font-weight: 600;\n  font-family: 'DM Sans';\n  text-transform: uppercase;\n}\n\n.permission-dialog button:hover {\n  background-color: #ff8371;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
