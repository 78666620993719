import React from 'react';
import './UniqueBackgroundCircles.css';

const UniqueBackgroundCircles: React.FC = () => {
  return (
    <div className="unique-bg-image">
      <div className="unique-bg-circle"></div>
      <div className="unique-bg-circle"></div>
      <div className="unique-bg-circle"></div>
      <div className="unique-bg-circle"></div>
      <div className="unique-bg-circle"></div>
      <div className="unique-bg-circle"></div>
    </div>
  );
};

export default UniqueBackgroundCircles;
