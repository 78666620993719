import { getPath, getPath2 } from '../../../../util/Util';
import './index.css';

interface GraphCircleProps {
  value: number;
  size?: number; // Optional prop to control size
}

const GraphCircle: React.FC<GraphCircleProps> = ({ value, size = 100 }) => {
  return (
    <div className="session-summary-inner-summary-main-left">
      <div
        className="session-summery-inner-summary-main-left-box"
        style={{ position: 'relative', width: size, height: size }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 80 80"
          fill="none"
        >
          <path d={getPath(10)} fill="#121212" />
        </svg>
        <svg
          style={{ position: 'absolute', left: 0, top: 0 }}
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 80 80"
          fill="none"
        >
          <path d={getPath(value)} fill="#FF8371" />
        </svg>
        {/* Uncomment and adjust if you want to include the third circle */}
        {/* <svg style={{ position: "absolute", left: 8, top: 8 }} xmlns="http://www.w3.org/2000/svg" width={size * 0.675} height={size * 0.675} viewBox="0 0 54 54" fill="none">
                    <path d={getPath2(7)} fill="#7FD1AE" />
                </svg> */}
      </div>
    </div>
  );
};

export default GraphCircle;
