import React from 'react';
import './NewHome.css';
import InfoBar from './InfoBar/InfoBar';
import NavBar from './NavBar/NavBar';
import MainContent from './MainContent/MainContent';
import Footer from './Footer/Footer';

const NewHomePage: React.FC = () => {
  return (
    <div className="new-homepage">
      <InfoBar />
      <div className="new-homepage-container">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <NavBar />
        <MainContent />
        <Footer />
      </div>
    </div>
  );
};

export default NewHomePage;
