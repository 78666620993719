import React from 'react';
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleComponent from "./GoogleComponent";
import { GOOGLE_CLIENT_ID } from "../../util/AppConstants";
import { PATH_CREATE_SESSION } from '../../util/SiteRoutes';
import SubFooter from '../SubFooter';

const Login = () => {
    if (!GOOGLE_CLIENT_ID) {
        console.error("Google Client ID is not defined");
        return null;
    }

    return (
        <React.Fragment>
        <div className="user-login-container">
            <div className="user-login-container-inner">
                <div className="user-login-container-inner-left">
                    <div className="user-login-container-inner-left-content">
                        <div className="user-login-container-inner-left-content-heading">
                            <div className="user-login-container-inner-left-content-heading1">Login to Espello!</div>
                        </div>
                        <div className="user-login-container-inner-left-content-secondary-buttons">
                            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                                <GoogleComponent redirectPath={PATH_CREATE_SESSION} loginWay='normalLogin' />
                            </GoogleOAuthProvider>
                        </div>
                    </div>
                </div>
                <div className="user-login-container-inner-right"></div>
            </div>
        </div>
        <SubFooter /></React.Fragment>
    );
};

export default Login;
