import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './InfoBar.css';

const InfoBar: React.FC = () => {
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleClick = () => {
    navigate('/user-waitlist'); // Navigate to the desired path
  };

  return visible ? (
    <div className="new-home-info-bar">
      <div className="new-home-info-bar-content">
        <span className='new-home-info-bar-heading'>
          Increase chances of cracking your upcoming interview :
        </span>
        <a href="#" className='new-home-canditate-join' onClick={handleClick}>
          {`   Click here to join beta program →`}
        </a>
      </div>
      <button className="new-home-close-button" onClick={() => setVisible(false)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
          <path d="M11.5023 11.5025L4.50073 4.50098" stroke="#F4EAE7" strokeWidth="1.00189" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M11.5023 4.50098L4.50073 11.5025" stroke="#F4EAE7" strokeWidth="1.00189" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
    </div>
  ) : null;
};

export default InfoBar;
