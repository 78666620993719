import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { ConversationTurnContextModel } from "../../../model/ConversationTurnContextModel";
import VideoRecorder from "../../Recorder/VideoRecorder";
import { Loader } from "../../Loader";
import {
  PATH_CREATE_SESSION,
  PATH_ENTERPRISE_LOGIN,
  PATH_ONGOING_SESSION,
  PATH_USER_RATE,
} from "../../../util/SiteRoutes";
import {
  SERVICE_URL_PYTHON,
  SPEAKER_INITIAL_TEXT,
  USER_CURRENT_SESSION_COOKIE,
  USER_LOGIN_WAY_KEY,
} from "../../../util/AppConstants";
import { InterviewerResponse } from "../../../model/InterviewerResponse";
import { Role } from "../../../model/Role";
import { ConversationTurn } from "../../../model/ConversationTurn";
import { SessionTranscript } from "../../../model/SessionTranscript";
import { Transcript } from "../../../model/Transcript";
import { SessionDetails } from "../CreateSession";
import { useNavigate } from "react-router-dom";
import { ExitMode } from "../../../model/ExitMode";
import { Model } from "../../Model";
import EnterpriseDisclaimer from "../GeneralCurrentSession/Disclaimer/Enterprise";
import ChatHeader from "../GeneralCurrentSession/ChatHeader";
import ChatContainer from "../GeneralCurrentSession/ChatContainer";

export const EnterpriseCurrentSession = () => {
  const navigate = useNavigate();

  const videoRecorderRef = useRef<{
    handleSaveVideo: () => void;
    stopRecording: () => void;
  } | null>(null);

  const [sessionId, setSessionId] = useState<string>("jvdgjsjvdjgsdjgvdss");
  const [timerOut, setTimerOut] = useState<boolean>(false);
  const [isCurrentSessionChatVisible, setIsCurrentSessionChatVisible] =
    useState<boolean>(true);
  const [sessionTranscript, setSessionTranscript] = useState<SessionTranscript>(
    { transcript: [{ role: Role.SPEAKER, text: SPEAKER_INITIAL_TEXT }] }
  );
  const [conversationTurn, setConversationTurn] = useState<ConversationTurn>(
    ConversationTurn.IDLE
  );
  const [sessionDetails, setSessionDetails] = useState<SessionDetails>(
    {} as SessionDetails
  );

  const [interviewerText, setInterviewerText] = useState<string>("");

  const proctorEnable =
    localStorage.getItem(USER_LOGIN_WAY_KEY) === "enterpriseLogin";
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [permissionDialogVisible, setPermissionDialogVisible] = useState(false);
  const [timer, setTimer] = useState(5);

  const [state, setState] = useState({
    isDialogVisible: true,
    showReenterFullscreenPrompt: false,
  });

  const exitAttempts = useRef<number>(0);
  const fullscreenActive = useRef<boolean>(false);

  const handleStartSessionClick = () => {
    if (proctorEnable) {
      setPermissionDialogVisible(true);
    } else {
      proceedWithSession();
    }
    enterFullscreen();
  };

  const enterFullscreen = () => {
    const elem = document.documentElement; // or the specific element you want to make fullscreen
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if ((elem as any).mozRequestFullScreen) {
      // Firefox
      (elem as any).mozRequestFullScreen();
    } else if ((elem as any).webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      (elem as any).webkitRequestFullscreen();
    } else if ((elem as any).msRequestFullscreen) {
      // IE/Edge
      (elem as any).msRequestFullscreen();
    }
    fullscreenActive.current = true; // Set fullscreenActive to true when entering fullscreen mode
  };

  const exitHandler = () => {
    if (
      !document.fullscreenElement &&
      !(document as any).webkitIsFullScreen &&
      !(document as any).mozFullScreen &&
      !(document as any).msFullscreenElement
    ) {
      if (fullscreenActive.current) {
        exitAttempts.current += 1;

        if (exitAttempts.current) {
          setState((prevState) => ({
            ...prevState,
            showReenterFullscreenPrompt: true,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            showReenterFullscreenPrompt: false,
          }));

          setTimeout(() => {
            alert(
              "You have exceeded the maximum number of attempts to exit fullscreen."
            );
          }, 1000);

          // Provide some time to stop and save the recording
          setTimeout(() => {
            navigate(PATH_USER_RATE, {
              state: { sessionId: sessionId, exitMode: ExitMode.MANUAL },
            });
          }, 2000);
        }
      }
    }
  };

  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) {
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        (document as any).msExitFullscreen();
      }
    }
    fullscreenActive.current = false; // Set fullscreenActive to false when exiting fullscreen mode
  };

  const handleReenterFullscreenClick = () => {
    setState((prevState) => ({
      ...prevState,
      showReenterFullscreenPrompt: false,
    }));
    enterFullscreen();
  };

  const proceedWithSession = () => {
    setState((prevState) => ({
      ...prevState,
      isDialogVisible: false,
    }));
    sendIntervieweeResponse(`Hi`);
  };

  const addSessionTranscript = (role: Role, text: string) => {
    const intervieweeTranscript: Transcript = { role, text };
    const tempTranscript: Transcript[] =
      sessionTranscript?.transcript as Transcript[];

    tempTranscript?.push(intervieweeTranscript);

    const tempSessionTranscript: SessionTranscript = {
      transcript: tempTranscript,
    };

    setSessionTranscript(tempSessionTranscript);
  };

  const changeConversationTurn = (turn: ConversationTurn) => {
    setConversationTurn(turn);
  };

  const sendIntervieweeResponse = async (
    intervieweeText: string,
    retries = 3
  ) => {
    changeConversationTurn(ConversationTurn.WAITING);
    // adding session transcript for interviewee
    addSessionTranscript(Role.INTERVIEWEE, intervieweeText);

    const url = `${SERVICE_URL_PYTHON}/process_text`;
    const data = {
      message: intervieweeText,
      session_id: sessionId,
    };

    localStorage.setItem('session_id',sessionId)

    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        const fetchedInterviewerText: InterviewerResponse = responseData;
        const interviewerResponse: string = fetchedInterviewerText.message;

        setInterviewerText(interviewerResponse);

        // adding session transcript for interviewer
        addSessionTranscript(Role.SPEAKER, interviewerResponse);

        break; // If the request is successful, exit the loop
      } catch (error) {
        console.error(`Error on attempt ${attempt}:`, error);
        if (attempt === retries) {
          // Optionally handle the case where all retries have failed
          console.error("All retry attempts failed");
          // You can update the UI or inform the user about the failure here
        }
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = ""; // Show confirmation dialog
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);

    return () => {
      document.removeEventListener("fullscreenchange", exitHandler);
      document.removeEventListener("webkitfullscreenchange", exitHandler);
      document.removeEventListener("mozfullscreenchange", exitHandler);
      document.removeEventListener("MSFullscreenChange", exitHandler);

      // Exit fullscreen when the component unmounts
      exitFullscreen();
    };
  }, []);

  // Window blur and focus event handlers
  useEffect(() => {
    const handleWindowBlur = () => {
      if (fullscreenActive.current) {
        // Handle window losing focus (e.g., user switches to a different window)
        console.log('Window lost focus');
        exitAttempts.current += 1;

        if (exitAttempts.current) {
          setState((prevState) => ({
            ...prevState,
            showReenterFullscreenPrompt: true,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            showReenterFullscreenPrompt: false,
          }));

          setTimeout(() => {
            alert('You have exceeded the maximum number of attempts to exit fullscreen.');
          }, 1000);

          // Provide some time to stop and save the recording
          setTimeout(() => {
            navigate(PATH_USER_RATE, {
              state: { sessionId: sessionId, exitMode: ExitMode.MANUAL },
            });
          }, 2000);
        }
      }
    };

    const handleWindowFocus = () => {
      // Handle window gaining focus
      console.log('Window gained focus');
    };

    window.addEventListener('blur', handleWindowBlur);
    window.addEventListener('focus', handleWindowFocus);

    return () => {
      window.removeEventListener('blur', handleWindowBlur);
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, []);

  const handleStopRecording = () => {
    if (videoRecorderRef.current) {
      videoRecorderRef.current.stopRecording();
      //Provide some time to stop the recording
      setTimeout(()=>{
        if (videoRecorderRef.current)
        videoRecorderRef.current.handleSaveVideo();
      },1000)
    }
  };

  const videoRecordingComponent: JSX.Element = (
    <VideoRecorder
      permissionGranted={permissionGranted}
      ref={videoRecorderRef}
    />
  );

  return (
    <React.Fragment>
      {sessionId !== undefined && isCurrentSessionChatVisible ? (
        <div className="chat-bot-container">
          {state.isDialogVisible ? (
            <>
              {permissionGranted && (
                <div className="timer-overlay">
                  <div className="timer-dialog-content">
                    <p>Starting in {timer} seconds...</p>
                  </div>
                </div>
              )}
              <EnterpriseDisclaimer
                permissionGranted={permissionGranted}
                setPermissionGranted={setPermissionGranted}
                setPermissionDialogVisible={setPermissionDialogVisible}
                setTimer={setTimer}
                permissionDialogVisible={permissionDialogVisible}
                handleStartSessionClick={handleStartSessionClick}
                proceedWithSession={proceedWithSession}
                videoRecordingComponent={videoRecordingComponent}
                setSessionId={setSessionId}
              />
            </>
          ) : (
            <>
              <ChatHeader
                conversationContext={
                  {
                    conversationTurn,
                    changeConversationTurn,
                  } as ConversationTurnContextModel
                }
                setTimerOut={setTimerOut}
                session_id={sessionId}
                sessionDetails={sessionDetails}
                setIsCurrentSessionChatVisible={setIsCurrentSessionChatVisible}
                handleStopRecording={handleStopRecording}
              />

              <div
                style={{
                  position: "absolute",
                  width: "200px",
                  height: "125px",
                  left: "0",
                  top: "90px",
                }}
              >
                {proctorEnable && permissionGranted && videoRecordingComponent}
              </div>
              <ChatContainer
                timerOut={timerOut}
                session_id={sessionId}
                interviewerText={interviewerText}
                sendIntervieweeResponse={sendIntervieweeResponse}
                conversationContext={
                  {
                    conversationTurn,
                    changeConversationTurn,
                  } as ConversationTurnContextModel
                }
                stopRecording={handleStopRecording}
              />
            </>
          )}
          <Model
            message={`You have exited fullscreen mode ${exitAttempts.current} times. Please click the button to re-enter fullscreen.`}
            actionContent="Re-enter Fullscreen"
            action={handleReenterFullscreenClick}
            visible={state.showReenterFullscreenPrompt}
          />
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default EnterpriseCurrentSession;
