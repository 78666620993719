import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './VerifyOTP.css';
import {
  PATH_CURRENT_SESSION_WITHOUT_SESSION_ID,
  PATH_ENTERPRISE_LOGIN,
} from '../../util/SiteRoutes';
import {
  SERVICE_URL_JAVA,
  SERVICE_URL_PYTHON,
  USER_CURRENT_SESSION_COOKIE,
} from '../../util/AppConstants';
import { useAuth } from '../Account/AuthContext';
import Header from '../Header';
import Footer from '../Footer';
import SubFooter from '../SubFooter';

export interface SessionDetails {
  role: string;
  mode: string;
  industry: string;
  companyType: string;
}

const VerifyOTP = () => {
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const { userDetails } = useAuth();

  useEffect(() => {
    // Retrieve email from navigation state or local storage
    const stateEmail = location.state?.email;
    const storedEmail = localStorage.getItem('email');
    if (stateEmail) {
      setEmail(stateEmail);
      localStorage.setItem('email', stateEmail);
    } else if (storedEmail) {
      setEmail(storedEmail);
    }
  }, [location.state]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        handleVerify();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [otp]);

  const createSession = async (userId: string) => {
    const url = `${SERVICE_URL_PYTHON}/save_session_details`;
    const sessionDetails: SessionDetails = {
      role: `${otp}`,
      mode: '',
      industry: '', // Default value for industry
      companyType: '',
    };
    const data = { userId, sessionDetails };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();

      if (result?.data.status === 'success') {
        sessionStorage.setItem(USER_CURRENT_SESSION_COOKIE, 'true');
        navigate(
          `${PATH_CURRENT_SESSION_WITHOUT_SESSION_ID}/${result?.data.sessionId}`
        );
      } else {
        console.error('An unknown error occurred');
        // TODO: Handle session not created
      }
    } catch (error: any) {
      console.error('Error:', error);
    }
  };

  const handleVerify = async () => {
    try {
      const response = await fetch(
        `${SERVICE_URL_JAVA}/enterprise/api/v1/verifyEnterpriseUser?uniqueCode=${otp}&email=${email}`,
        {
          method: 'GET',
        }
      );

      if (response.ok) {
        const result = await response.json();
        const { status, data } = result;

        if (status === 'success' && data.verified) {
          localStorage.setItem('isVerified', 'true');
          localStorage.setItem('source', 'verifyOtp');

          if (userDetails && userDetails.user_id) {
            createSession(userDetails.user_id);
          } else {
            console.error('User details are not available');
            // TODO: Handle the case when userDetails is null
          }
        } else {
          alert(data.errorDescription || 'Invalid OTP or email');
          localStorage.setItem('isVerified', 'false');
        }
      } else {
        alert('Error verifying OTP');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('Error verifying OTP');
    }
  };

  const handleEmailChange = () => {
    localStorage.removeItem('email');
    navigate(PATH_ENTERPRISE_LOGIN);
  };

  return (
    <React.Fragment><Header hasBorderBottom={false}/>
    <div className="verify-otp-container">
      <div className="verify-otp-inner">
        <h2>Enter Company Code</h2>
        <div className="email-display">
          <span
            style={{
              color: '#6E6E6E',
              fontWeight: '700',
              letterSpacing: '1px',
            }}
          >
            {email}
          </span>
          <button onClick={handleEmailChange}>
            <span
              style={{
                paddingRight: '2px',
                fontWeight: '600',
                letterSpacing: '1px',
              }}
            >
              Logout
            </span>
          </button>
        </div>
        <div className="verify-input">
          <input
            type="text"
            placeholder="Enter code..."
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </div>
      </div>
      <div className="continue">
        <button
          onClick={handleVerify}
          className={otp ? 'enabled' : ''}
          disabled={!otp}
        >
          Continue
        </button>
      </div>
    </div>
    <SubFooter />
    </React.Fragment>
  );
};

export default VerifyOTP;
