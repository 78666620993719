import './index.css'

interface ModelProps {
  message : string;
  actionContent : string;
  visible: boolean;
  action: () => void;
}

export const Model: React.FC<ModelProps> = ({ message,actionContent,visible, action }) => {
  return visible ? (
    <div className="permission-dialog-overlay">
      <div className="permission-dialog">
        <div className="permission-dialog-content">
          <p style={{ color: "#FFF" }}>
            {message}
          </p>
          <div className="disclaimer-permission-button">
            <button onClick={action}>{actionContent}</button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
