import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import {
  PATH_BLOGS,
  PATH_HOME,
  PATH_USER_WAITLIST,
} from '../../util/SiteRoutes';

interface HeaderProps {
  scrollToComponent?: () => void;
  enableOtherButtons?: boolean;
  hasBorderBottom?: boolean;
}

const Header: FC<HeaderProps> = ({
  scrollToComponent = () => {},
  enableOtherButtons = false,
  hasBorderBottom = true,
}) => {
  const [isAndroidHeaderCollapsed, setIsAndroidHeaderCollapsed] =
    useState<boolean>(true);

  const borderClass = hasBorderBottom ? 'borderBottom' : 'noBorderBottom';
  const className = `header-pc ${borderClass}`;

  const navigate = useNavigate();

  const onClickEnterprise = () => {
    setIsAndroidHeaderCollapsed(true);
    scrollToComponent();
  };

  const onClickUserWaitlist = () => {
    navigate(PATH_USER_WAITLIST);
  };

  const onClickLogo = () => {
    navigate(PATH_HOME);
  };

  const onClickBlogs = () => {
    window.open(PATH_BLOGS, '_blank');
  };

  return (
    <React.Fragment>
      <div className={className}>
        <div className="header-left" onClick={onClickLogo}>
          <div className="header-left-logo-icon">
            <svg
              width="37"
              height="33"
              viewBox="0 0 37 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.8847 14.8902C19.2769 14.6176 23.4846 14.2794 24.0178 10.1635C25.0948 1.85097 17.6157 0 11.5995 0C5.5834 0 -0.415057 1.98558 0.0225772 10.1635C0.647971 21.85 18.7452 27.5289 12.8111 19.4545C9.81521 15.3781 12.4261 15.1682 15.8847 14.8902ZM11.1966 10.3682C12.1922 10.1626 12.8683 9.36206 12.7068 8.58024C12.5453 7.79842 11.6073 7.33134 10.6118 7.53699C9.61622 7.74264 8.94009 8.54315 9.10159 9.32497C9.26309 10.1068 10.2011 10.5739 11.1966 10.3682Z"
                fill="#FF8371"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.9291 26.4995C22.8343 25.6399 20.2361 24.5738 20.7314 21.8572C21.7317 16.3709 26.8465 16.7119 30.6594 17.9299C34.4723 19.1478 37.872 21.6206 35.9391 26.715C33.1768 33.9951 20.5574 33.9305 25.9531 30.0144C28.677 28.0374 27.0648 27.3758 24.9291 26.4995ZM28.6887 24.8065C27.9419 24.3859 27.6042 23.5695 27.9345 22.983C28.2648 22.3965 29.138 22.262 29.8848 22.6826C30.6317 23.1032 30.9693 23.9196 30.639 24.5061C30.3087 25.0926 29.4356 25.2271 28.6887 24.8065Z"
                fill="#FF8371"
              />
            </svg>
          </div>
          <div className="header-left-logo">Espello</div>
        </div>
        <div className="header-right">
          {enableOtherButtons && (
            <div className="header-right-buttons" onClick={onClickEnterprise}>
              <div className="header-right-buttons-enterprise">Enterprises</div>
            </div>
          )}
          {enableOtherButtons && (
            <div className="header-right-buttons" onClick={onClickBlogs}>
              <div className="header-right-buttons-enterprise">Blogs</div>
            </div>
          )}
          {enableOtherButtons && (
            <div className="header-right-buttons" onClick={onClickUserWaitlist}>
              <div
                className="header-right-buttons-login"
                style={{ color: '#FF8371', textDecoration: 'underline' }}
              >
                Join the waitlist
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="header-android">
        <div className="header-android-left" onClick={onClickLogo}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="20"
            viewBox="0 0 23 20"
            fill="none"
            onClick={onClickLogo}
          >
            <path
              file-rule="evenodd"
              clip-rule="evenodd"
              d="M9.33566 14.4397C12.5028 14.7417 21.2514 15.5762 22.06 9.33501C23.0491 1.70008 16.1797 0 10.654 0C5.12826 0 -0.381223 1.82373 0.0207368 9.33501C0.244316 13.5129 2.21015 19.1989 9.4877 19.4919C13.3621 19.6479 19.3073 17.4978 19.3073 16.8545C19.3073 15.8235 18.3294 15.899 16.5353 16.0375C15.3415 16.1297 13.7863 16.2497 11.9174 16.0903C9.96003 15.9233 8.19948 14.7871 8.19948 14.4017C8.19948 14.3313 8.6284 14.3722 9.33566 14.4397ZM10.3131 9.58245C11.2711 9.38456 11.9217 8.61425 11.7663 7.86192C11.6109 7.10959 10.7083 6.66012 9.75029 6.85802C8.7923 7.05591 8.14167 7.82622 8.29708 8.57856C8.45249 9.33089 9.35508 9.78035 10.3131 9.58245Z"
              fill="#FF8371"
            />
            <path
              d="M16.4962 15.5156C16.6215 15.296 16.9388 15.2981 17.0611 15.5193L17.6614 16.6044C17.7813 16.8212 17.6235 17.0868 17.3758 17.0852L16.161 17.0772C15.9134 17.0756 15.759 16.8079 15.8818 16.5928L16.4962 15.5156Z"
              fill="#FF8371"
            />
          </svg>
        </div>
        <div
          className="header-android-right"
          onClick={() => setIsAndroidHeaderCollapsed(!isAndroidHeaderCollapsed)}
        >
          {enableOtherButtons && (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                style={{ display: isAndroidHeaderCollapsed ? 'block' : 'none' }}
              >
                <path
                  d="M3.00098 18.75H21.001V16.7498H3.00098V18.75ZM3.00098 13.7498H21.001V11.7502H3.00098V13.7498ZM3.00098 6.75V8.75016H21.001V6.75H3.00098Z"
                  fill="#F4EAE7"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                style={{ display: isAndroidHeaderCollapsed ? 'none' : 'block' }}
              >
                <path
                  d="M18.749 6.81887L17.1792 5.24902L11.999 10.4292L6.81887 5.24902L5.24902 6.81887L10.4292 11.999L5.24902 17.1792L6.81887 18.749L11.999 13.5689L17.1792 18.749L18.749 17.1792L13.5689 11.999L18.749 6.81887Z"
                  fill="#F4EAE7"
                />
              </svg>
            </>
          )}
        </div>
      </div>
      <div
        className="header-android-open"
        style={{ display: isAndroidHeaderCollapsed ? 'none' : 'block' }}
      >
        <div className="header-android-open-button" onClick={onClickEnterprise}>
          Enterprises
        </div>
        <div className="header-android-open-button" onClick={onClickBlogs}>
          Blogs
        </div>
        <div
          className="header-android-open-button"
          onClick={onClickUserWaitlist}
        >
          Join the waitlist
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
