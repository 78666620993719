export type VideoQuality = "720p" | "480p" | "360p" | "240p" | "144p";

// Define options for each video quality, including width, height, and frame rate
export const qualityOptions: Record<VideoQuality, { width: number; height: number; frameRate: number }> = {
  "720p": { width: 1280, height: 720, frameRate: 30 },
  "480p": { width: 854, height: 480, frameRate: 30 },
  "360p": { width: 640, height: 360, frameRate: 30 },
  "240p": { width: 426, height: 240, frameRate: 30 },
  "144p": { width: 256, height: 144, frameRate: 15 },
};

export const formatOptions = ["webm", "mp4"];

export const WEBM = "webm";
export const MP4 = "mp4";
export const VIDEO_WEBM = "video/webm";
export const VIDEO_MP4 = "video/mp4";